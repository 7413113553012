import { useCallback, useEffect, useState } from "react";
import { IITEMIOptions, IITGuwahatiProfileDetailInputInterface, PaymentMethod } from "../../../constants/IIt-course/iit-form-onboarding";
import TrimesterFee from "../../../components/V2/IITGOnboarding/TrimesterFee";
import OrientationStep from "../../../components/V2/IITGOnboarding/OrientationStep";
import ProfileDetailStep from "../../../components/V2/IITGOnboarding/ProfileDetailStep";
import { Constants, IITMandiESOnboardingSteps } from "../../../components/common/constants";
import OnboardingStepTracker from "../../../components/IIT-Onboarding/OnboardingStepTracker";
import { Box, Button, Skeleton } from "@chakra-ui/react";
import { useCourses } from "../../../api/utils/hooks/useCourse";
import usePayment, { useGetPaymentStatus, useGetSemesterFee } from "../../../api/utils/hooks/usePayment";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { formatDateWithOrdinal, getIITGuwahatiOnboardingDates, removeInvalidKeyValueFromEMIForm, removeInvalidKeyValues } from "../../../utils/utils";
import OnboardingCompletedScrren from "../../../components/V2/IITGOnboarding/OnboardingCompletedScrren";
import calculateFinalPayment from "../../../components/common/V2/CalculateFinalPayment";
import { useMutation } from "react-query";
import { completeOnboarding, runAadhaarOCR } from "../../../api/utils/api/v2/application";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../../components/common/general/SnackBar";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { sendTrackingEvent } from "../../../components/common/utils/gtm";
import { useHistory } from "react-router-dom";
import { CourseSelected, ProgramProfileType } from "../../../enums/ProgramType.enum";
import { ModalTemplate, ModalTemplateV2 } from "../../../components/common/general";
import AadhaarIneligibleModalContent from "../../../components/V2/ModalContent/AadhaarIneligibleModalContent";
import ShareModal from "../../Courses/ShareModal";
import dayjs from "dayjs";
import { SemesterPaymentType } from "../../../api/schemas/schema";
import PaymentSection from "../../../components/V2/IITGOnboarding/EmiPayment/EmiFee";
import { getActiveBatch } from "../../../api/utils/api/v2/batch";
import { CreatePhonePayPayment, GetPhonePayPaymentStatus } from "../../../api/utils/api/v2/payment";
import { useActiveBatch } from "../../../api/utils/hooks/useBatch";

const IITMandiESOnboarding = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const { data: allCourses } = useCourses();
  const { data: profile, refetch: refetchProfile, isLoading: isProfileLoading } = useProfile();
  const { data: semesterData, isLoading: isSemesterDataLoading } = useGetSemesterFee(profile?.program_profile_type as CourseSelected);
  const { data: paymentData, isLoading: isRegisterationPaymenStatusLoading } = useGetPaymentStatus("REGISTRATION_FEES");
  const { data: semesterPaymentData, isLoading: isSemesterPaymentStatusLoading, refetch: refetchSemesterFees } = useGetPaymentStatus("SEMESTER_FEES");
  const { data: semesterEMIPaymentData, isLoading: isSemesterEMIPaymentStatusLoading, refetch: refetchSemesterEMIFees } = useGetPaymentStatus("SEMESTER_FEES_EMI");
  const { data: activeApplication, refetch: refetchActiveApplication, isLoading: isActiveApplicationDataLoading } = useActiveApplication();
  const { data: activeBatch, isLoading: isActiveBatchLoading } = useActiveBatch("21");

  const [selectedCourseId, setSelectedCourseId] = useState<string>();
  const [isDuplicateNumber, setIsDuplicateNumber] = useState<boolean>(false);
  const [dob, setDOB] = useState<string>();
  const [emiOptionsData, setEmiOptionsData] = useState<IITEMIOptions>();
  const [selfPaidEMIUser, setSelfPaidEMIUser] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [disablePaymentBtn, setDisablePaymentBtn] = useState<boolean>(false);
  const [aadhaarErrorMessage, setAadhaarErrorMessage] = useState<string>("");
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<number>(1);
  const [paymentAmount, setPaymentAmount] = useState<undefined | number>(undefined);
  const [timelines, setTimelines] = useState<{ date: string; overdue: boolean }[]>();
  const [activeTab, setActiveTab] = useState<PaymentMethod>(PaymentMethod.EMI_PAYMENT);
  const [aadhaarMismatchModalContent, setAadhaarMismatchModalContent] = useState<boolean>(false);
  const [aadhaarIneligibleModalContent, setAadhaarIneligibleModalContent] = useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [couponValue, setCouponValue] = useState("");

  const hasUserPaidSemesterFees = semesterPaymentData?.is_iit_mandiES_course && semesterPaymentData?.status === "PAID" ? true : false;
  const paymentBtnText = activeTab === PaymentMethod.EMI_PAYMENT ? "Submit" : "Pay Course Fees now";
  const shouldDisablePaymentBtn = PaymentMethod.EMI_PAYMENT ? disablePaymentBtn : false;
  const hasPaidForIITMandiBARegistration = paymentData?.status === "PAID" && paymentData?.is_iit_mandiES_course ? true : false;
  const hasUserPaidSemesterEMIFees = semesterEMIPaymentData?.is_iit_mandiES_course && semesterEMIPaymentData?.status === "PAID" ? true : false;

  const [profileDetailForm, setProfileDetailForm] = useState<IITGuwahatiProfileDetailInputInterface>();
  const [isAdmitCardModalOpen, setIsAdmitCardModalOpen] = useState<boolean>(false);
  const [shouldHideDirectPayment, setShouldHideDirectPayment] = useState<boolean>(false);
  const [isMobileInputInvalid, setIsMobileInputInvalid] = useState<boolean>(false);
  const [isAlternativeMobileInputInvalid, setIsAlternativeMobileInputInvalid] = useState<boolean>(false);
  const [semesterPaymentOptionsData, setSemesterPaymentOptionsData] = useState<SemesterPaymentType>("FULLY_PAID");

  const { mutate: completeOnboardingMutate, isLoading: completeOnboardingLoading } = useMutation(completeOnboarding, {
    onSuccess: () => {
      refetchActiveApplication();
      setIsAdmitCardModalOpen(true);
      setCurrentOnboardingStep(5);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: (data) => {
      if (currentOnboardingStep === 2 && data?.meta && data?.meta?.hasClickedOnJoinSlackForMandiES === "True") {
        setCurrentOnboardingStep(3);
      } else if (currentOnboardingStep === 3 && data?.meta && data?.meta?.emiOptionSelectedForMandiES) {
        if (data?.meta?.selfPaidForMandiES === "true" || data?.meta?.selfPaidForMandiES === "True") {
          setSelfPaidEMIUser(true);
        } else {
          setSelfPaidEMIUser(false);
        }
        refetchProfile();
      } else if (currentOnboardingStep === 4 && profile?.aadhaar_verified) {
        completeOnboardingMutate();
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: updateDOBMutate, isLoading: updateDOBLoading } = useMutation(updateProfile, {
    onSuccess: (data) => {
      if (data.date_of_birth) {
        if (profileDetailForm?.aadharBack && profileDetailForm?.aadharFront) {
          runAadhaarOCRMutate({
            aadhaar_front_url: profileDetailForm?.aadharFront,
            aadhaar_back_url: profileDetailForm?.aadharBack,
          });
        }
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: saveProfileMutate, isLoading: saveProfileLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      snackbar.success("Details saved successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: runAadhaarOCRMutate, isLoading: runAadhaarOCRLoading } = useMutation(runAadhaarOCR, {
    onSuccess: (data) => {
      let updatedProfileDetailForm = { ...profileDetailForm };

      const hasAadharFront = profile?.meta?.aadharFront;
      const hasAadharBack = profile?.meta?.aadharBack;

      if (!hasAadharFront && !hasAadharBack) {
        updatedProfileDetailForm = {
          ...profileDetailForm,
          aadharFront: profileDetailForm?.aadharFront || "No file uploaded",
          aadharBack: profileDetailForm?.aadharBack || "No file uploaded",
        };
      } else if (!hasAadharFront && hasAadharBack) {
        updatedProfileDetailForm = {
          ...profileDetailForm,
          aadharFront: profileDetailForm?.aadharFront || "No file uploaded",
        };
      } else if (hasAadharFront && !hasAadharBack) {
        updatedProfileDetailForm = {
          ...profileDetailForm,
          aadharBack: profileDetailForm?.aadharBack || "No file uploaded",
        };
      }

      updateProfileMutate({
        meta: {
          panCardFront: profileDetailForm?.panCardFront || "No file uploaded",
          panCardBack: profileDetailForm?.panCardBack || "No file uploaded",
          ...updatedProfileDetailForm,
        },
      });
      if (data.has_aadhaar_matched) {
        const eventName = "d_onboarding_next4";
        completeOnboardingMutate();
        sendTrackingEvent({
          event: eventName,
          eventLabel: location.pathname,
        });
      } else {
        setAadhaarMismatchModalContent(true);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        setAadhaarErrorMessage(e.message);
        setAadhaarIneligibleModalContent(true);
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { startRazorPay, isApiLoading, isLoading } = usePayment("SEMESTER_FEES", profile?.program_profile_type as ProgramProfileType);

  const { startRazorPay: startRazorPayForEMI, isApiLoading: isSemesterEMIPaymentApiLoading, isLoading: isSemesterEMIPaymentLoading } = usePayment(
    "SEMESTER_FEES_EMI",
    profile?.program_profile_type as ProgramProfileType
  );

  const { mutate: createPhonePayPayment, isLoading: isPhonePayApiLoading } = useMutation(CreatePhonePayPayment, {
    onSuccess: (data) => {
      const paymentUrl = data?.redirect_url;
      window.open(paymentUrl, "_self");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: verifyPhonePayPayment, isLoading: isVerifyPhonePayApiLoading } = useMutation(GetPhonePayPaymentStatus, {
    onSuccess: () => {
      refetchSemesterFees();
      refetchSemesterEMIFees();
      // remove query param from url
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handlePaymentCreateOption, isLoading: isActiveApiBatchLoading } = useMutation(getActiveBatch, {
    onSuccess: (data) => {
      const isPhonePayEnabled = data && data.meta_data && data.meta_data.isPhonePayEnabled === "True";
      if (discountAmount && discountAmount > 0) {
        if (isPhonePayEnabled) {
          const redirectUrl = window.location.pathname;
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES",
            redirect_url: redirectUrl,
            semester_payent_type: semesterPaymentOptionsData,
            coupon_code: couponValue,
          });
        } else {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES", couponValue, semesterPaymentOptionsData);
        }
      } else {
        if (isPhonePayEnabled) {
          const redirectUrl = window.location.pathname;
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES",
            redirect_url: redirectUrl,
            semester_payent_type: semesterPaymentOptionsData,
          });
        } else {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES", null, semesterPaymentOptionsData);
        }
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handlePaymentCreateOptionForEmi, isLoading: isActiveEMIApiBatchLoading } = useMutation(getActiveBatch, {
    onSuccess: (data) => {
      const isPhonePayEnabled = data && data.meta_data && data.meta_data.isPhonePayEnabled === "True";
      if (isPhonePayEnabled) {
        const redirectUrl = window.location.pathname;
        createPhonePayPayment({
          course_id: Number(selectedCourseId),
          payment_type: "SEMESTER_FEES_EMI",
          redirect_url: redirectUrl,
          semester_payent_type: "EMI_PAID",
        });
      } else {
        startRazorPayForEMI(selectedCourseId || "", "SEMESTER_FEES_EMI", null, "EMI_PAID");
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleSubmit = () => {
    if (currentOnboardingStep === 2) {
      updateProfileMutate({
        meta: {
          // hasOrientationVideoWatchedForMandiES: true,
          hasClickedOnJoinSlackForMandiES: true,
        },
      });
    } else if (currentOnboardingStep === 3) {
      if (!hasUserPaidSemesterEMIFees && profile?.meta?.emiOptionSelectedForMandiES) {
        handlePaymentCreateOptionForEmi(selectedCourseId || "");
      } else if (activeTab === PaymentMethod.EMI_PAYMENT) {
        const updateEMIForm = removeInvalidKeyValueFromEMIForm(emiOptionsData as IITEMIOptions);
        if (Object.keys(updateEMIForm).length > 0) {
          updateProfileMutate({
            meta: updateEMIForm,
          });
        }
      } else if (activeTab === PaymentMethod.ONE_TIME_PAYMENT) {
        sendTrackingEvent({
          event: "p_pay_IIT_MANDI_BA_semester",
          eventLabel: window.location.href,
          p_amount: (paymentAmount || 0).toString(),
        });
        handlePaymentCreateOption(selectedCourseId || "");
      }
    } else if (currentOnboardingStep === 4) {
      if (!profile?.date_of_birth) {
        updateDOBMutate({ date_of_birth: dob });
      } else if (!profile?.aadhaar_verified && profileDetailForm?.aadharBack && profileDetailForm?.aadharFront) {
        runAadhaarOCRMutate({
          aadhaar_front_url: profileDetailForm?.aadharFront,
          aadhaar_back_url: profileDetailForm?.aadharBack,
        });
      } else {
        updateProfileMutate({
          meta: profileDetailForm,
        });
      }
    }
  };

  const handleSaveDetails = () => {
    const validProfileDetailForm = removeInvalidKeyValues(profileDetailForm as IITGuwahatiProfileDetailInputInterface);
    if (Object.keys(validProfileDetailForm).length > 0) {
      if (dob) {
        saveProfileMutate({
          meta: validProfileDetailForm,
          date_of_birth: dob,
        });
      } else {
        saveProfileMutate({
          meta: validProfileDetailForm,
        });
      }
    }
  };

  const handleProfileDisabled = useCallback(() => {
    // Common checks for all user types
    const commonChecks =
      profile?.aadhaar_verified && profile?.meta?.panCardFront
        ? profileDetailForm?.creditInforConcentForMandiES === "False" || !profileDetailForm?.creditInforConcentForMandiES
        : !profile?.meta?.panCardFront && profile?.aadhaar_verified
        ? !(profileDetailForm?.creditInforConcentForMandiES === "True" && !!profileDetailForm?.panCardFront)
        : !(!!profileDetailForm?.panCardFront && !!profileDetailForm?.aadharBack && !!profileDetailForm?.aadharFront && profileDetailForm?.creditInforConcentForMandiES === "True");
    // Checks for users who have paid semester fees
    if (hasUserPaidSemesterFees) {
      return !!(
        commonChecks ||
        !profileDetailForm?.dob ||
        !profileDetailForm?.parentGuardianNameForMandiES ||
        !profileDetailForm?.parentGuardianEmailForMandiES ||
        isMobileInputInvalid ||
        isAlternativeMobileInputInvalid ||
        !profileDetailForm?.parentGuardianPhoneNumberForMandiES ||
        !profileDetailForm?.alternativePhoneNumberForMandiES ||
        isDuplicateNumber ||
        (!profileDetailForm?.parentGuardianPhoneNumberForMandiES && isMobileInputInvalid) ||
        (!profileDetailForm?.alternativePhoneNumberForMandiES && isAlternativeMobileInputInvalid)
      );
    }

    // Checks for self-paid EMI users
    if (selfPaidEMIUser) {
      const selfPaidChecks =
        profile?.meta?.employmentTypeForMandiES === "self_employed"
          ? !profileDetailForm?.businessNameForMandiES || !profileDetailForm?.monthlyBusinessIncomeForMandiES
          : !profileDetailForm?.companyNameForMandiES || !profileDetailForm?.monthlySalaryForMandiES;
      return !!(
        commonChecks ||
        !profileDetailForm?.dob ||
        !profileDetailForm?.parentGuardianNameForMandiES ||
        !profileDetailForm?.parentGuardianEmailForMandiES ||
        isMobileInputInvalid ||
        isAlternativeMobileInputInvalid ||
        !profileDetailForm?.parentGuardianPhoneNumberForMandiES ||
        !profileDetailForm?.alternativePhoneNumberForMandiES ||
        isDuplicateNumber ||
        (!profileDetailForm?.parentGuardianPhoneNumberForMandiES && isMobileInputInvalid) ||
        (!profileDetailForm?.alternativePhoneNumberForMandiES && isAlternativeMobileInputInvalid) ||
        selfPaidChecks
      );
    }

    // Checks for non-self-paid EMI users
    const isMotherOrRelativeCoApplicant = ["Mother", "Relative"].includes(profile?.meta?.coApplicantForMandiES || "") && !profileDetailForm?.coApplicantRelationProofForMandiES;
    const coApplicantEmployedTypeChecks =
      profile?.meta?.coApplicantEmploymentTypeForMandiES === "self_employed"
        ? !profileDetailForm?.coApplicantBusinessNameForMandiES || !profileDetailForm?.coApplicantMonthlyBusinessIncomeForMandiES
        : !profileDetailForm?.coApplicantCompanyNameForMandiES || !profileDetailForm?.coApplicantMonthlySalaryForMandiES;

    return !!(
      commonChecks ||
      !profileDetailForm?.dob ||
      !profileDetailForm?.coApplicantEmailForMandiES ||
      !profileDetailForm?.coApplicantNameForMandiES ||
      isMobileInputInvalid ||
      (!profileDetailForm?.coApplicantPhoneNumberForMandiES && isMobileInputInvalid) ||
      !profileDetailForm?.coApplicantAadhaarBackForMandiES ||
      !profileDetailForm?.coApplicantAadhaarFrontForMandiES ||
      !profileDetailForm?.coApplicantProfilePicForMandiES ||
      !profileDetailForm?.coApplicantPanCardForMandiES ||
      isMotherOrRelativeCoApplicant ||
      coApplicantEmployedTypeChecks
    );
  }, [hasUserPaidSemesterFees, selfPaidEMIUser, profileDetailForm, profile, isMobileInputInvalid, isAlternativeMobileInputInvalid, isDuplicateNumber]);

  // Calculating all the possible changes to payment amount
  useEffect(() => {
    if (semesterData) {
      let semsterFee = semesterData?.full_course_fees || 0;
      if (semesterPaymentOptionsData === "FULLY_PAID") {
        semsterFee = semesterData?.full_course_fees || 0;
      } else if (semesterPaymentOptionsData === "PARTIALLY_PAID") {
        semsterFee = semesterData?.partial_semester_one_fees || 0;
      }
      const { finalAmount } = calculateFinalPayment({
        registrationFee: semsterFee,
      });

      const courseId = semesterData?.id.toString() || "";

      setSelectedCourseId(courseId);
      setPaymentAmount(finalAmount);
    }
  }, [profile, semesterData, semesterPaymentOptionsData]);

  useEffect(() => {
    const isRegisterationDone = paymentData && hasPaidForIITMandiBARegistration;
    // profile?.meta?.hasOrientationVideoWatchedForMandiES === "True" ||
    const isOrientationVideoWatched = profile?.meta && profile?.meta?.hasClickedOnJoinSlackForMandiES === "True";
    const isOnboardingCompleted = activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE";
    const hasPaidSemesterEMI = semesterEMIPaymentData && semesterEMIPaymentData.is_iit_mandiES_course && semesterEMIPaymentData.status === "PAID";
    if (paymentData && !isRegisterationDone) {
      history.push("/minor-in-embedded-system");
    } else if (!isOrientationVideoWatched) {
      setCurrentOnboardingStep(2);
    } else if (profile?.meta && !profile?.meta?.emiOptionSelectedForMandiES && !hasUserPaidSemesterFees) {
      setCurrentOnboardingStep(3);
    } else if (profile?.meta && profile?.meta?.emiOptionSelectedForMandiES && !hasPaidSemesterEMI) {
      setCurrentOnboardingStep(3);
    } else if (hasPaidSemesterEMI) {
      if (activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE") {
        history.push("/minor-in-embedded-system");
      } else {
        setCurrentOnboardingStep(4);
      }
    } else if (activeApplication && !isOnboardingCompleted) {
      setCurrentOnboardingStep(4);
    } else if (activeApplication && isOnboardingCompleted) {
      setCurrentOnboardingStep(5);
    }
  }, [paymentData, semesterPaymentData, activeApplication, profile, hasUserPaidSemesterFees, hasPaidForIITMandiBARegistration, semesterEMIPaymentData, hasUserPaidSemesterEMIFees]);

  useEffect(() => {
    if (paymentData && paymentData?.created_at && paymentData?.created_at[ProgramProfileType.IIT_MANDI_ES] && allCourses && allCourses[0].active_batch?.start_date) {
      const data = getIITGuwahatiOnboardingDates(paymentData?.created_at && paymentData?.created_at[ProgramProfileType.IIT_MANDI_ES], allCourses[0].active_batch?.start_date);

      setTimelines(data);
    }
  }, [paymentData?.created_at, allCourses]);

  useEffect(() => {
    if (profile?.meta?.selfPaidForMandiES === "true" || profile?.meta?.selfPaidForMandiES === "True") {
      setSelfPaidEMIUser(true);
    } else {
      setSelfPaidEMIUser(false);
    }
    setActiveTab(PaymentMethod.ONE_TIME_PAYMENT);
  }, [profile, profile?.meta]);

  // Filling Profile Meta Details in state
  useEffect(() => {
    if (!isProfileLoading && profile && profile?.meta) {
      setProfileDetailForm({
        ...profile.meta,
        dob: profile.date_of_birth,
      });
      if (profile.date_of_birth) {
        setDOB(dayjs(profile.date_of_birth).format("YYYY-MM-DD"));
      }
    }
  }, [profile]);

  useEffect(() => {
    const merchantTransactionId = location.search.split("merchantTransactionId=")[1];
    if (merchantTransactionId) {
      verifyPhonePayPayment(merchantTransactionId);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      profileDetailForm?.alternativePhoneNumberForMandiES &&
      !isMobileInputInvalid &&
      !isAlternativeMobileInputInvalid &&
      profileDetailForm?.parentGuardianPhoneNumberForMandiES &&
      profileDetailForm?.alternativePhoneNumberForMandiES === profileDetailForm?.parentGuardianPhoneNumberForMandiES
    ) {
      setIsDuplicateNumber(true);
    } else {
      setIsDuplicateNumber(false);
    }
  }, [profileDetailForm?.alternativePhoneNumberForMandiES, profileDetailForm?.parentGuardianPhoneNumberForMandiES, isMobileInputInvalid, isAlternativeMobileInputInvalid]);

  if (
    isProfileLoading ||
    isActiveApplicationDataLoading ||
    isSemesterPaymentStatusLoading ||
    isRegisterationPaymenStatusLoading ||
    isSemesterDataLoading ||
    isSemesterEMIPaymentStatusLoading ||
    isActiveBatchLoading
  ) {
    return <Skeleton h={`calc(100vh - ${Constants.headerHeight})`} w={{ base: "100vw", lg: `100vw` }} isLoaded={false} />;
  }

  return (
    <>
      <Box bg={"#F2F6FF"}>
        <Box
          px={{ base: "16px", md: "24px" }}
          display={"flex"}
          flexDir={"column"}
          gap={{ base: "24px", md: "30px" }}
          py={{ base: "18px", md: "24px" }}
          pos={"relative"}
          minH={`calc(100vh - ${Constants.headerHeight})`}
          pb={{ base: "80px", md: "110px" }}
          maxW={1240}
          mx="auto"
        >
          {/* Onboarding Step Tracker */}
          <OnboardingStepTracker
            currentOnboardingStep={currentOnboardingStep}
            showIITGuwahatiDashboard={true}
            onboardingSteps={IITMandiESOnboardingSteps}
            timelines={timelines}
            isAllStepsCompleted={currentOnboardingStep === 4}
          />

          {currentOnboardingStep === 2 && (
            <OrientationStep
              isIITRopar={false}
              isIITMandi={true}
              linkedInContent={activeBatch?.meta_data?.linkedinContent}
              slackLink={activeBatch?.meta_data?.slackLink}
              slug={activeApplication?.slug}
            />
          )}
          {currentOnboardingStep === 3 &&
            (profile?.meta && profile?.meta?.emiOptionSelectedForMandiES ? (
              <PaymentSection
                profile={profile}
                courseStartDate={formatDateWithOrdinal(
                  dayjs(paymentData?.created_at && paymentData?.created_at[ProgramProfileType.IIT_MANDI_ES])
                    .add(5, "days")
                    .format("MM/DD/YYYY")
                )}
                instalmentAmount={Number(activeBatch?.meta_data?.semester_one_fees_emi)}
                registerationPaymentDate={paymentData?.created_at && paymentData?.created_at[ProgramProfileType.IIT_MANDI_ES]}
              />
            ) : (
              <TrimesterFee
                setEmiOptionsData={setEmiOptionsData}
                emiOptionsData={emiOptionsData}
                handleSubmit={handleSubmit}
                updateProfileLoading={updateProfileLoading}
                registerationPaymentDate={paymentData?.created_at && paymentData?.created_at[ProgramProfileType.IIT_MANDI_ES]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                disablePaymentBtn={disablePaymentBtn}
                setDisablePaymentBtn={setDisablePaymentBtn}
                isForIITRopar={false}
                isForIITMandi={false}
                isForIITMandiES={true}
                setDiscountAmount={setDiscountAmount}
                setCouponValue={setCouponValue}
                shouldHideDirectPayment={shouldHideDirectPayment}
                setSemesterPaymentOptionsData={setSemesterPaymentOptionsData}
                semesterPaymentOptionsData={semesterPaymentOptionsData}
              />
            ))}
          {currentOnboardingStep === 4 && (
            <ProfileDetailStep
              selfPaidEMIUser={selfPaidEMIUser}
              profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
              setProfileDetailForm={setProfileDetailForm}
              profile={profile}
              dob={dob}
              setDOB={setDOB}
              refetchProfile={refetchProfile}
              isMobileInputInvalid={isMobileInputInvalid}
              setIsMobileInputInvalid={setIsMobileInputInvalid}
              isAlternativeMobileInputInvalid={isAlternativeMobileInputInvalid}
              setIsAlternativeMobileInputInvalid={setIsAlternativeMobileInputInvalid}
            />
          )}
          {currentOnboardingStep === 5 && <OnboardingCompletedScrren />}
          <Box
            bg={"#FFF"}
            display={{
              base: currentOnboardingStep === 5 ? "none" : "block",
              md: currentOnboardingStep === 3 || currentOnboardingStep === 5 ? "none" : "block",
            }}
            bottom={0}
            py={{ base: 2, md: 4 }}
            boxShadow={"0px -1px 0px 0px #D9D9D9"}
            pos={"fixed"}
            w={"full"}
            textAlign={"end"}
            right={{ base: 0 }}
            zIndex={{ base: 10, lg: 1 }}
          >
            {currentOnboardingStep === 4 && (
              <Button
                variant="primary"
                size="md"
                mr={4}
                isLoading={saveProfileLoading}
                disabled={updateProfileLoading || saveProfileLoading || runAadhaarOCRLoading || completeOnboardingLoading || !profileDetailForm || Object.keys(profileDetailForm || {}).length === 0}
                onClick={handleSaveDetails}
              >
                Save Details
              </Button>
            )}
            <Button
              variant="primary"
              size="md"
              mr={4}
              isLoading={
                updateProfileLoading ||
                runAadhaarOCRLoading ||
                completeOnboardingLoading ||
                updateDOBLoading ||
                isApiLoading ||
                isLoading ||
                isSemesterEMIPaymentApiLoading ||
                isSemesterEMIPaymentLoading ||
                isPhonePayApiLoading ||
                isActiveApiBatchLoading ||
                isActiveEMIApiBatchLoading
              }
              disabled={
                updateProfileLoading ||
                saveProfileLoading ||
                updateDOBLoading ||
                (currentOnboardingStep === 4 && handleProfileDisabled()) ||
                isFileUploading ||
                runAadhaarOCRLoading ||
                completeOnboardingLoading ||
                (currentOnboardingStep === 3 && shouldDisablePaymentBtn) ||
                isVerifyPhonePayApiLoading
              }
              onClick={handleSubmit}
            >
              {currentOnboardingStep === 3 && !hasUserPaidSemesterEMIFees && profile?.meta?.emiOptionSelectedForMandiES
                ? "Pay 1st Month EMI"
                : currentOnboardingStep === 5
                ? "Take benchmarking test"
                : currentOnboardingStep === 3
                ? paymentBtnText
                : "Next"}
            </Button>
          </Box>
        </Box>
      </Box>

      <ModalTemplateV2
        hideIconWithConfetti={true}
        closeOnOverlayClick={false}
        waiting={true}
        isOpen={runAadhaarOCRLoading}
        onClose={() => {
          // setOnboardingCompleteModalOpen(false);
        }}
        showConfetti={false}
        text="Please wait while we are verifying your adhaar"
      />

      {/* Aadhaar already exists */}
      <ModalTemplate
        title=""
        isOpen={aadhaarIneligibleModalContent}
        onClose={() => {
          setAadhaarIneligibleModalContent(false);
          refetchActiveApplication();
          refetchProfile();
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="484px"
        bgColor="#FFFAFB"
      >
        <AadhaarIneligibleModalContent aadhaarErrorMessage={aadhaarErrorMessage} s3FrontImageUrl={profileDetailForm?.aadharFront} setAadhaarIneligibleModalContent={setAadhaarIneligibleModalContent} />
      </ModalTemplate>

      {/* Aadhaar Mismatch Modal */}
      <ModalTemplate
        title=""
        isOpen={aadhaarMismatchModalContent}
        onClose={() => {
          setAadhaarMismatchModalContent(false);
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="484px"
        bgColor="#FFFAFB"
      >
        <AadhaarIneligibleModalContent
          isMismatch={true}
          aadhaarErrorMessage={aadhaarErrorMessage}
          s3FrontImageUrl={profileDetailForm?.aadharFront}
          setAadhaarMismatchModalContent={setAadhaarMismatchModalContent}
          setCurrentOnboardingStep={setCurrentOnboardingStep}
          setAadhaarErrorMessage={setAadhaarErrorMessage}
          setAadhaarIneligibleModalContent={setAadhaarIneligibleModalContent}
          isIITGuwahatiDashboard={true}
        />
      </ModalTemplate>

      {/* Show Admit Card Modal */}
      <ModalTemplate
        title=""
        isOpen={isAdmitCardModalOpen}
        onClose={() => {
          setIsAdmitCardModalOpen(false);
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="484px"
      >
        <ShareModal
          gtmEvents={{
            facebook: "d_onboarding_admit_fb",
            copyUrl: "d_onboarding_admit_cp",
            linkedin: "d_onboarding_admit_in",
            twitter: "d_onboarding_admit_x",
          }}
          certificateUrl={activeApplication?.admit_card_link || ""}
          name={profile?.name}
          isIITGuwahatiDashboard={false}
          isIITMandiDashboard={false}
          isIITRoparDashboard={false}
          isIITMandiV2Dashboard={false}
          isIITMandiESDashboard={true}
          setIsAdmitCardModalOpen={setIsAdmitCardModalOpen}
        />
      </ModalTemplate>
    </>
  );
};

export default IITMandiESOnboarding;
