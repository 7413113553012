import { useState, useEffect, useRef } from "react";
import useSnackBar from "../../../components/common/general/SnackBar";
import { useMutation, useQuery } from "react-query";
import { CreatePaymentRecordPost, GetPaymentStatus, GetSemesterFee } from "../api/v2/payment";
import { axiosErrorHandler } from "../error";
import { RazorPaySuccessPost } from "../api/v2/payment";
import { CreatePaymentRecordType, PaymentType, ProgramProfileType, SemesterPaymentType } from "../../schemas/schema";
import { useHistory } from "react-router-dom";
import { CourseSelected } from "../../../enums/ProgramType.enum";

function usePayment(payment_type: PaymentType, program_profile_type: ProgramProfileType, isAdressEmpty?: boolean, onSuccess?: () => void) {
  const [isLoading, setisLoading] = useState(false);
  const history = useHistory();
  const ref = useRef<{ course: string }>(null);
  const snackbar = useSnackBar();
  const { mutate: paymentStatusCheckerMutate, isLoading: isLoadingPaymentSuccessChecker } = useMutation(RazorPaySuccessPost, {
    onSuccess: async () => {
      snackbar.success("Payment Successful!");

      if (onSuccess) {
        onSuccess();
        return;
      }
      // redirect to success page
      setTimeout(() => {
        if (!isAdressEmpty) {
          history.push(
            program_profile_type === "IIT_GUWAHATI"
              ? "/iitg-onboarding"
              : program_profile_type === "IIT_MANDI"
              ? "iit-onboarding"
              : program_profile_type === "MASAI"
              ? "/empower-her"
              : program_profile_type === "IIT_MANDI_V2"
              ? "/minor-in-cse-onboarding"
              : program_profile_type === "IIT_MANDI_ES"
              ? "/minor-in-embedded-system-onboarding"
              : "/iit-ropar-onboarding"
          );
        } else if (payment_type === "SEMESTER_FEES" || payment_type === "SEMESTER_FEES_EMI") {
          history.push("/");
        }
      }, 3000);
      refetch();
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      // redirect to failure page
    },
  });
  const { mutate: paymentMutate, isLoading: isPaymentLoading } = useMutation(CreatePaymentRecordPost, {
    onSuccess: async (data) => {
      snackbar.success("Starting payment process!");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_CLIENT_ID || "",
        order_id: data?.order_id,
        name: `${ref.current?.course || ""}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handler: (response: any) => {
          if (response?.razorpay_payment_id && response?.razorpay_order_id && response?.razorpay_signature) {
            // send request to backend to verify payment
            paymentStatusCheckerMutate({
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_signature: response?.razorpay_signature,
            });
          }
        },
      };
      if (window && window.Razorpay) {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    },
    onError: (err) => {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    },
  });

  const { refetch } = useGetPaymentStatus(payment_type);
  useEffect(() => {
    if (isLoadingPaymentSuccessChecker === false || isPaymentLoading === false) {
      setisLoading(false);
    } else {
      setisLoading(true);
    }
  }, [isLoadingPaymentSuccessChecker, isPaymentLoading]);
  // display razorpay modal once order id is available
  const startRazorPay = async (course: string, paymentType: PaymentType, coupon_code?: string | null, semester_payent_type?: SemesterPaymentType) => {
    if (ref.current != null) {
      ref.current.course = course;
    }
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      snackbar.error("Payment Gateway failed to load. Please check internet connection!");
      return;
    }
    let params = { payment_type: paymentType, course_id: +course };
    if (semester_payent_type) {
      params = {
        ...params,
        semester_payent_type: semester_payent_type,
      } as CreatePaymentRecordType;
    }
    if (coupon_code) {
      params = {
        ...params,
        coupon_code: coupon_code,
      } as CreatePaymentRecordType;
    }
    paymentMutate({ ...params });
  };

  return {
    startRazorPay,
    isLoading: isLoading,
    isApiLoading: isLoadingPaymentSuccessChecker || isPaymentLoading,
  };
}

export default usePayment;
const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export function useGetPaymentStatus(paymentType: PaymentType) {
  return useQuery({
    queryKey: ["getPaymentStatus", paymentType],
    queryFn: async () => await GetPaymentStatus(paymentType),
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGetSemesterFee(course_profile: CourseSelected) {
  return useQuery({
    queryKey: "getPaymentStatus",
    queryFn: async () => await GetSemesterFee(course_profile),
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: !!course_profile,
  });
}
