import Layout from "../components/common/layouts/Layout";
import { Redirect, Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import PrivateRoute from "../components/common/auth/PrivateRoute";
import { Skeleton } from "@chakra-ui/react";
import ApplicationRoutes from "./ApplicationRoutes";

import { HeaderContext, HeaderReducer, initState } from "../components/common/layouts/HeaderState";
import { useReducer } from "react";

import Dashboard from "../pages/V2/Dashboard";
import Dost from "../pages/V2/Dost";

import MSATResult from "../pages/V2/MSATResult";
import Alumni from "../pages/V2/Alumni";
import ProfileV2 from "../pages/V2/Profile/ProfileV2";
import ScrollToTop from "../components/common/ScrollToTop";

import AllEvents from "../components/V2/Admin/Events/AllEvents";
import DostAdmin from "../components/V2/Admin/Dost/Dost";
import CounsellingAdmin from "../components/V2/Admin/Counselling/Counselling";
import AddNewEvent from "../components/V2/Admin/Events/AddNewEvent";
import MSAT from "../pages/MSAT";
import UsersAdmin from "../components/V2/Admin/Users/UsersAdmin";
import IITCourseResultsPage from "../components/iit-course/IITCourseResultsPage";
import JoinAlumniSession from "../pages/V2/Alumni/JoinAlumniSession";
import AdminIndex from "../components/V2/Admin";
import AllPayments from "../components/V2/Admin/Payments";
import GetUserToUpdate from "../components/V2/Admin/Users/GetUserToUpdate";
import GetUserToUpdatePersonalInfo from "../components/V2/Admin/Users/GetUserToUpdatePersonalInfo";
import OnboardingRevamp from "../pages/V2/Onboarding";
import IITMandiV2Dashboard from "../pages/V2/IITMandiV2-Dashboard";
import IITMandiV2Onboarding from "../pages/V2/IITMandiV2-Onboarding";
import UpdateBatchInfo from "../components/V2/Admin/Batch/UpdateBatchInfo";
import AddCouponCodes from "../components/V2/Admin/Coupon/AddCouponCodes";
import CreateBatch from "../components/V2/Admin/Batch/CreateBatch";

import IITMandiESDashboard from "../pages/V2/IITMandiBA-Dashboard";
import IITMandiESOnboarding from "../pages/V2/IITMandiBA-Onboarding";

function Loading() {
  return (
    <>
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
    </>
  );
}

// Lazy loaded routes
const KitchenSink = loadable(() => import("../pages/kitchen-sink/KitchenSink"), {
  fallback: <Loading />,
});

const GridsExample = loadable(() => import("../pages/kitchen-sink/GridsExample"), {
  fallback: <Loading />,
});

const Courses = loadable(() => import("../pages/V2/Courses"), {
  fallback: <Loading />,
});

const ProfileApplications = loadable(() => import("../pages/Profile/ProfileApplications"), {
  fallback: <Loading />,
});

const Referrals = loadable(() => import("../pages/V2/Referral/index"), {
  fallback: <Loading />,
});

const CourseDetails = loadable(() => import("../pages/CourseDetails"), {
  fallback: <Loading />,
});

const Events = loadable(() => import("../pages/Events/Events"), {
  fallback: <Loading />,
});

const Activities = loadable(() => import("../pages/Activities/Activities"), {
  fallback: <Loading />,
});

const ActivityDetails = loadable(() => import("../pages/Activities/Details"), {
  fallback: <Loading />,
});
const LiveActivity = loadable(() => import("../pages/Activities/LiveActivity"), {
  fallback: <Loading />,
});
const BatchDocs = loadable(() => import("../pages/Application/msat/BatchDocs"), {
  fallback: <Loading />,
});
const MSATSyllabus = loadable(() => import("../pages/Application/msat/new-msat/MSATSyllabus"), {
  fallback: <Loading />,
});

const SelfLearning = loadable(() => import("../pages/SelfLearning"), {
  fallback: <Loading />,
});

const AddReferral = loadable(() => import("../pages/Profile/referrals/add/AddReferral"), {
  fallback: <Loading />,
});

const ProfileEdit = loadable(() => import("../pages/Profile/ProfileEdit"), {
  fallback: <Loading />,
});

const MSATSuccess = loadable(() => import("../pages/Application/msat/MSATSuccess"), {
  fallback: <Loading />,
});

const BookEvent = loadable(() => import("../components/events/book-event/BookEvent"), {
  fallback: <Loading />,
});
const ContestDetails = loadable(() => import("../components/events/book-event/ContestDetails"), {
  fallback: <Loading />,
});

const IITCourseDetails = loadable(() => import("../pages/IIt-course-detail"), {
  fallback: <Loading />,
});

const IITMedia = loadable(() => import("../pages/media"), {
  fallback: <Loading />,
});

const IITCurriculum = loadable(() => import("../pages/curriculum"), {
  fallback: <Loading />,
});

const IITVideos = loadable(() => import("../pages/videos"), {
  fallback: <Loading />,
});

const IITMandiCourse = loadable(() => import("../pages/IIt-Mandi"), {
  fallback: <Loading />,
});

const IITCourseFeesPage = loadable(() => import("../components/iit-course/IITCourseFeesPage"), {
  fallback: <Loading />,
});

const IITCourseTakeBenchmarkPage = loadable(() => import("../components/iit-course/IITCourseTakeBenchmarkPage"), {
  fallback: <Loading />,
});

const IITOnboarding = loadable(() => import("../pages/V2/IIT-Onboarding"), {
  fallback: <Loading />,
});

const JobsPage = loadable(() => import("../pages/V2/Jobs"), {
  fallback: <Loading />,
});

const IITGOnboarding = loadable(() => import("../pages/V2/IITG-Onboarding"), {
  fallback: <Loading />,
});

const IITRoparDashboard = loadable(() => import("../pages/V2/IITRopar-Dashboard"), {
  fallback: <Loading />,
});

const IITRoparOnboarding = loadable(() => import("../pages/V2/IITRopar-Onboarding"), {
  fallback: <Loading />,
});

const SonuSoodScholarship = loadable(() => import("../pages/V2/Sonu-Sood-Scholarship"), {
  fallback: <Loading />,
});
const ScholarshipOnboarding = loadable(() => import("../pages/V2/scholarship-onboarding"), {
  fallback: <Loading />,
});

const EmpowerHerOnboarding = loadable(() => import("../pages/V2/empowerHer-Onboarding"), {
  fallback: <Loading />,
});

const EmpowerHer = loadable(() => import("../pages/empower-her"), {
  fallback: <Loading />,
});

type RouteProps = {
  path: string;
  name: string;
  isPrivate: boolean;
  component: JSX.Element;
  hidden: boolean;
  useLayout: boolean;
  exact?: boolean;
  showRightNav?: boolean;
};

const routes: RouteProps[] = [
  {
    path: "/",
    name: "Home",
    exact: true,
    isPrivate: true,
    component: <Dashboard />,

    hidden: false,
    useLayout: true,
  },
  {
    path: "/result",
    name: "MSAT Result",
    exact: true,
    isPrivate: true,
    component: <MSATResult />,

    hidden: false,
    useLayout: true,
  },
  {
    path: "/courses",
    name: "Courses",
    exact: true,
    isPrivate: true,
    component: <Courses />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch-docs",
    name: "Batch Docs",
    exact: true,
    isPrivate: true,
    component: <BatchDocs />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/msat-syllabus",
    name: "MSAT Syllabus",
    exact: true,
    isPrivate: true,
    component: <MSATSyllabus heading={"MSAT Syllabus"} />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/scholarship-test-syllabus",
    name: "Scholarship Test Syllabus",
    exact: true,
    isPrivate: true,
    component: <MSATSyllabus heading={"Scholarship Test Syllabus"} isScholarhipTest={true} />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/courses",
    name: "Courses",
    exact: true,
    isPrivate: true,
    component: <Courses />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/events",
    name: "Events",
    exact: true,
    isPrivate: true,
    component: <Events />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/activities",
    name: "Activities",
    exact: true,
    isPrivate: true,
    component: <Activities />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/activities/:activityID/details",
    name: "Activity Details",
    exact: true,
    isPrivate: true,
    component: <ActivityDetails />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/activities/:activityID/details/live",
    name: "Activity Details Live",
    exact: true,
    isPrivate: true,
    component: <LiveActivity />,
    hidden: false,
    useLayout: false,
    showRightNav: false,
  },
  {
    path: "/events/:eventID/book",
    name: "Book Event",
    exact: true,
    isPrivate: true,
    component: <BookEvent />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/contests/:contestID/details",
    name: "Contest Details",
    exact: true,
    isPrivate: true,
    component: <ContestDetails />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/self-learning",
    name: "Self Learning",
    exact: true,
    isPrivate: true,
    component: <SelfLearning />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/referral-program",
    name: "Referral Program",
    exact: true,
    isPrivate: true,
    component: <Referrals />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/isa",
    name: "ISA",
    exact: true,
    isPrivate: true,
    component: <></>,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/faqs",
    name: "FAQs",
    exact: true,
    isPrivate: true,
    component: <></>,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/courses/:courseId/details",
    name: "Course Details",
    exact: true,
    isPrivate: true,
    component: <CourseDetails />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/profile",
    name: "Profile",
    exact: true,
    isPrivate: true,
    component: <ProfileV2 />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/profile/edit",
    name: "Profile Edit",
    exact: true,
    isPrivate: true,
    component: <ProfileEdit />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/profile/referrals",
    name: "Applicant Onboarding",
    exact: true,
    isPrivate: true,
    component: <Referrals />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/profile/referrals/add",
    name: "Applicant Onboarding",
    exact: true,
    isPrivate: true,
    component: <AddReferral />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/profile/applications",
    name: "Profile Applications",
    exact: true,
    isPrivate: true,
    component: <ProfileApplications />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/msat-success",
    name: "Applicant cleared MSAT and Communication Evaluation",
    exact: true,
    isPrivate: true,
    component: <MSATSuccess />,
    hidden: false,
    useLayout: true,
  },

  {
    path: "/kitchen-sink",
    name: "Kitchen Sink",
    exact: true,
    isPrivate: true,
    component: <KitchenSink />,
    hidden: process.env.NODE_ENV === "production",
    useLayout: true,
  },
  {
    path: "/kitchen-sink/grids",
    name: "Kitchen Sink",
    exact: true,
    isPrivate: true,
    component: <GridsExample />,
    //FIXME: don't do this. just push in the array if (env===dev). no need to support a new flag for 1 component
    // This is a default flag provided by sytem
    hidden: process.env.NODE_ENV === "production",
    useLayout: true,
  },
  {
    path: "/onboarding/:courseId",
    name: "OnboardingV2",
    exact: true,
    isPrivate: true,
    component: <OnboardingRevamp />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/alumni",
    name: "OnboardingV2",
    exact: true,
    isPrivate: true,
    component: <Alumni />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/alumni-session",
    name: "OnboardingV2",
    exact: true,
    isPrivate: true,
    component: <JoinAlumniSession />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/dost",
    name: "Dost",
    exact: true,
    isPrivate: true,
    component: <Dost />,
    hidden: false,
    useLayout: true,
  },

  // admin routes
  {
    path: "/admin",
    name: "Admin",
    exact: true,
    isPrivate: true,
    component: <AdminIndex />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/events/all",
    name: "All Events",
    exact: true,
    isPrivate: true,
    component: <AllEvents />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/events/create",
    name: "Create Event",
    exact: true,
    isPrivate: true,
    component: <AddNewEvent />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/users",
    name: "Dost Admin",
    exact: true,
    isPrivate: true,
    component: <UsersAdmin />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },

  {
    path: "/admin/batch",
    name: "Update Batch Details",
    exact: true,
    isPrivate: true,
    component: <UpdateBatchInfo />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },

  {
    path: "/admin/create-new-batch",
    name: "Create Batch Details",
    exact: true,
    isPrivate: true,
    component: <CreateBatch />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },

  {
    path: "/admin/coupon",
    name: "Add Coupon Codes",
    exact: true,
    isPrivate: true,
    component: <AddCouponCodes />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },

  {
    path: "/admin/users/update-kyc-details",
    name: "Update User Details",
    exact: true,
    isPrivate: true,
    component: <GetUserToUpdate />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/users/update-personal-details",
    name: "Update User Details",
    exact: true,
    isPrivate: true,
    component: <GetUserToUpdatePersonalInfo />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/users/update-user-batch",
    name: "Update User Batch",
    exact: true,
    isPrivate: true,
    component: <GetUserToUpdate />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/users/empower-her-update-user",
    name: "Empower Her Updaye User",
    exact: true,
    isPrivate: true,
    component: <GetUserToUpdate />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/dost",
    name: "Dost Admin",
    exact: true,
    isPrivate: true,
    component: <DostAdmin />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admin/counselling",
    name: "Counselling Admin",
    exact: true,
    isPrivate: true,
    component: <CounsellingAdmin />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },

  // payment related routes
  {
    path: "/admin/payments/all",
    name: "Payments",
    exact: true,
    isPrivate: true,
    component: <AllPayments />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/msat",
    name: "MSAT",
    exact: true,
    isPrivate: true,
    component: <MSAT />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/jobs",
    name: "Jobs",
    exact: true,
    isPrivate: true,
    component: <JobsPage />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/admission-and-fees",
    name: "Entrance Test",
    exact: true,
    isPrivate: true,
    component: <MSAT />,
    hidden: false,
    useLayout: true,
    showRightNav: false,
  },
  {
    path: "/mc-iitg-cs",
    name: "IIT Course Detail",
    exact: true,
    isPrivate: true,
    component: <IITCourseDetails />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/curriculum",
    name: "Curriculum",
    exact: true,
    isPrivate: true,
    component: <IITCurriculum />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/iit-mandi-ai-ml",
    name: "IIT Mandi",
    exact: true,
    isPrivate: true,
    component: <IITMandiCourse />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/videos",
    name: "Videos",
    exact: true,
    isPrivate: true,
    component: <IITVideos />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/media",
    name: "Media",
    exact: true,
    isPrivate: true,
    component: <IITMedia />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/registration-fees",
    name: "Registration Fees",
    exact: true,
    isPrivate: true,
    component: <IITCourseFeesPage />,
    hidden: false,
    useLayout: false,
  },
  {
    path: "/complete-benchmarking-test",
    name: "Complete Benchmarking Test",
    exact: true,
    isPrivate: true,
    component: <IITCourseTakeBenchmarkPage />,
    hidden: false,
    useLayout: false,
  },
  {
    path: "/complete-onboarding",
    name: "Complete Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITCourseResultsPage />,
    hidden: false,
    useLayout: false,
  },
  {
    path: "/iit-onboarding",
    name: "IIT Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITOnboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/iitg-onboarding",
    name: "IITG Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITGOnboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/iit-ropar-onboarding",
    name: "IITRopar Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITRoparOnboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/iit-ropar-ai",
    name: "IITRopar Dashboard",
    exact: true,
    isPrivate: true,
    component: <IITRoparDashboard />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/minor-in-cse",
    name: "IITMandi New Dashboard",
    exact: true,
    isPrivate: true,
    component: <IITMandiV2Dashboard />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/minor-in-cse-onboarding",
    name: "IITMandi next gen Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITMandiV2Onboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/sonu-sood-scholarship",
    name: "Sonu Sood Scholarship",
    exact: true,
    isPrivate: true,
    component: <SonuSoodScholarship />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/scholarship-onboarding",
    name: "Scholarship Onbaording",
    exact: true,
    isPrivate: true,
    component: <ScholarshipOnboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/empowerHer-onboarding",
    name: "EmpowerHer Onboarding",
    exact: true,
    isPrivate: true,
    component: <EmpowerHerOnboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/empower-her",
    name: "Empower Her",
    exact: true,
    isPrivate: true,
    component: <EmpowerHer />,
    hidden: false,
    useLayout: true,
  },

  {
    path: "/minor-in-embedded-system",
    name: "IITMandi Embedded System Dashboard",
    exact: true,
    isPrivate: true,
    component: <IITMandiESDashboard />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/minor-in-embedded-system-onboarding",
    name: "IITMandi Embedded System Onboarding",
    exact: true,
    isPrivate: true,
    component: <IITMandiESOnboarding />,
    hidden: false,
    useLayout: true,
  },
];

const Routes: React.FC = () => {
  const [state, dispatch] = useReducer(HeaderReducer, initState);
  return (
    <>
      <HeaderContext.Provider value={[state, dispatch]}>
        <ScrollToTop>
          <Switch>
            {routes.map((route) =>
              route.isPrivate ? (
                <PrivateRoute key={route.path} exact={route.exact} path={route.path}>
                  {route.useLayout ? <Layout showRightNav={route?.showRightNav}>{route.component}</Layout> : <>{route.component}</>}
                </PrivateRoute>
              ) : (
                // <Route key={route.path} {...route}>
                <Route key={route.path} path={route.path}>
                  {route.useLayout ? <Layout showRightNav={route?.showRightNav}>{route.component}</Layout> : <>{route.component}</>}
                </Route>
              )
            )}
            <ApplicationRoutes />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </ScrollToTop>
      </HeaderContext.Provider>
    </>
  );
};

export default Routes;
