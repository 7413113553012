export enum Constants {
  headerHeight = "4.5rem",
  sidePanelWidth = "16.5rem",
  bottomNavbarHeight = "3.5rem",
}

export const seoData = {
  "IIT-COURSE-DETAIL-PAGE": {
    title: "IIT Guwahati Micro-credit Program in Computer Science Engineering",
    description: "Kickstart your tech career with micro-credit program in CSE from IIT Guwahati. Created and taught by IIT professors with assured placement outcomes. Apply Now!",
    ogTitle: "IIT Guwahati Micro-credit Program in Computer Science Engineering",
    ogDescription: "Kickstart your tech career with micro-credit program in CSE from IIT Guwahati. Created and taught by IIT professors with assured placement outcomes. Apply Now!",
    ogImageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Artboard_163_1_eedc2836e1.png",
    ogUrl: "https://dashboard.masaischool.com/mc-iitg-cs",
  },
  "IIT-VIDEO-PAGE": {
    title: "Videos",
    description: "Hear from our IIT Professors and Industry Mentors",
    ogTitle: "Videos",
    ogDescription: "Hear from our IIT Professors and Industry Mentors",
    ogImageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Artboard_167_ad69b3c308.png",
    ogUrl: "https://dashboard.masaischool.com/videos",
  },
  "IIT-MEDIA-PAGE": {
    title: "Media",
    description: "Take a look at what the Media has to say about us.",
    ogTitle: "Media",
    ogDescription: "Take a look at what the Media has to say about us.",
    ogImageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Artboard_166_eaef90135d.png",
    ogUrl: "https://dashboard.masaischool.com/media",
  },
  "IIT-CURRICULUM": {
    title: "Program Curriculum",
    description: "Curriculum details: Micro-credit program in CSE from IIT Guwahati",
    ogTitle: "Program Curriculum",
    ogDescription: "Curriculum details: Micro-credit program in CSE from IIT Guwahati",
    ogImageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/OG_Images_IIT_Gx_Masai_IITG_x_Masai_7b3099e07b.png",
    ogUrl: "https://dashboard.masaischool.com/curriculum",
  },
  "MASAI-ONWARD": {
    title: "Masai School",
    description: "Launch your dream career in tech with Masai School",
    ogTitle: "Masai School",
    ogDescription: "Launch your dream career in tech with Masai School",
    ogImageUrl: "https://masai-com.s3.ap-south-1.amazonaws.com/static/og/og/open-graph.png",
    ogUrl: "https://dashboard.masaischool.com",
  },
};

export const onboardingSteps = [
  {
    id: 1,
    key: "registration-fee",
    value: "Registration Fee",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "orientation-videos",
    value: "Orientation Videos",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "trimester-fee",
    value: "Remaining course Fee",
    marginLeft: "-12px",
  },
  {
    id: 4,
    key: "profile-details",
    value: "Profile Details",
    marginLeft: "-12px",
  },
  {
    id: 5,
    key: "benchmarking-test",
    value: "Benchmarking Test",
    marginLeft: "-12px",
  },
];

export const iitMandiOnboardingSteps = [
  {
    id: 1,
    key: "registration-fee",
    value: "Registration Fee",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "orientation-videos",
    value: "Orientation Videos",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "trimester-fee",
    value: "Remaining course Fee",
    marginLeft: "-12px",
  },
  {
    id: 4,
    key: "profile-details",
    value: "Profile Details",
    marginLeft: "-12px",
  },
  {
    id: 5,
    key: "benchmarking-test",
    value: "Benchmarking Test",
    marginLeft: "-12px",
  },
];

export const IITRoparOnboardingSteps = [
  {
    id: 1,
    key: "registration-fee",
    value: "Registration Fee",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "orientation-videos",
    value: "Orientation Videos",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "trimester-fee",
    value: "Course Fees",
    marginLeft: "-12px",
  },
  {
    id: 4,
    key: "profile-details",
    value: "Profile Details",
    marginLeft: "-12px",
  },
];

export const ScholarshipOnboardingSteps = [
  {
    id: 1,
    key: "youtube-video",
    value: "YouTube video",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "take-MSAT",
    value: "Take MSAT",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "results",
    value: "Results",
    marginLeft: "-12px",
  },
];

export const IITMandiV2OnboardingSteps = [
  {
    id: 1,
    key: "registration-fee",
    value: "Registration Fee",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "orientation-videos",
    value: "Start Onboarding",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "trimester-fee",
    value: "Course Fees",
    marginLeft: "-12px",
  },
  {
    id: 4,
    key: "profile-details",
    value: "Profile Details",
    marginLeft: "-12px",
  },
];

export const IITMandiESOnboardingSteps = [
  {
    id: 1,
    key: "registration-fee",
    value: "Registration Fee",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "orientation-videos",
    value: "Start Onboarding",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "trimester-fee",
    value: "Course Fees",
    marginLeft: "-12px",
  },
  {
    id: 4,
    key: "profile-details",
    value: "Profile Details",
    marginLeft: "-12px",
  },
];

export const urlsToRestrictForIITG = [
  "/courses",
  "/learn",
  "/activities",
  "/referral-program",
  "/dost",
  "/alumni",
  "/result",
  "/alumni-session",
  "/onboarding",
  "/iit-onboarding",
  "/iit-mandi-ai-ml",
  "/iit-ropar-ai",
  "/iit-ropar-onboarding",
  "/empower-her",
  "/empowerHer-onboarding",
  "/minor-in-cse",
  "/minor-in-cse-onboarding",
];

export const urlsToRestrictForIITMandi = [
  "/courses",
  "/learn",
  "/activities",
  "/referral-program",
  "/dost",
  "/alumni",
  "/result",
  "/alumni-session",
  "/onboarding",
  "/iitg-onboarding",
  "/mc-iitg-cs",
  "/iit-ropar-ai",
  "/iit-ropar-onboarding",
  "/empower-her",
  "/empowerHer-onboarding",
  "/minor-in-cse",
  "/minor-in-cse-onboarding",
];

export const urlsToRestrictForMasai = [
  "/media",
  "/videos",
  "/curriculum",
  "/mc-iitg-cs",
  "/iit-ropar-ai",
  "/iit-onboarding",
  "/iit-mandi-ai-ml",
  "/iitg-onboarding",
  "/life-at-guwahati",
  "/registration-fees",
  "/admission-and-fees",
  "/iit-ropar-onboarding",
  "/minor-in-cse",
  "/minor-in-cse-onboarding",
];

export const urlsToRedirectForIITRopar = [
  "/mc-iitg-cs",
  "/videos",
  "/curriculum",
  "/life-at-guwahati",
  "/admission-and-fees",
  "/iit-onboarding",
  "/iitg-onboarding",
  "/iit-mandi-ai-ml",
  "/learn",
  "/courses",
  "/activities",
  "/referral-program",
  "/dost",
  "/alumni",
  "/result",
  "/onboarding",
  "/alumni-session",
  "/empower-her",
  "/empowerHer-onboarding",
  "/minor-in-cse",
  "/minor-in-cse-onboarding",
];

export const urlsToRedirectForIITMandiV2 = [
  "/iit-ropar-ai",
  "/mc-iitg-cs",
  "/videos",
  "/curriculum",
  "/life-at-guwahati",
  "/admission-and-fees",
  "/iit-onboarding",
  "/iitg-onboarding",
  "/iit-mandi-ai-ml",
  "/learn",
  "/courses",
  "/referral-program",
  "/dost",
  "/alumni",
  "/result",
  "/onboarding",
  "/alumni-session",
  "/empower-her",
  "/empowerHer-onboarding",
  "/iit-ropar-onboarding",
];

export const urlsToRedirectForIITMandiBA = [
  "/mimor-in-cse",
  "/minor-in-cse-onboarding",
  "/iit-ropar-ai",
  "/mc-iitg-cs",
  "/videos",
  "/curriculum",
  "/life-at-guwahati",
  "/admission-and-fees",
  "/iit-onboarding",
  "/iitg-onboarding",
  "/iit-mandi-ai-ml",
  "/learn",
  "/courses",
  "/activities",
  "/referral-program",
  "/dost",
  "/alumni",
  "/result",
  "/onboarding",
  "/alumni-session",
  "/empower-her",
  "/empowerHer-onboarding",
  "/iit-ropar-onboarding",
];
