import React, { useEffect, useState } from "react";
import { Box, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Skeleton, Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../../../common/general/SnackBar";
import { useHistory } from "react-router-dom";
import ProfileDetailStep from "../../IITGOnboarding/ProfileDetailStep";
import { IITEMIOptions, IITGuwahatiProfileDetailInputInterface } from "../../../../constants/IIt-course/iit-form-onboarding";
import { useAdminProfile } from "../../../../api/utils/hooks/useAdmin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { updateProfileByEmail } from "../../../../api/utils/api/v2/admin";
import { removeInvalidKeyValueFromEMIForm, removeInvalidKeyValues } from "../../../../utils/utils";
import EMIPlanOptions from "../../IITGOnboarding/EMIPlanOptions";

interface UpdateUserDetailsProps {
  email: string;
}

const UpdateUserDetails = ({ email }: UpdateUserDetailsProps) => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [profileDetailForm, setProfileDetailForm] = useState<IITGuwahatiProfileDetailInputInterface>();
  const [selfPaidEMIUser, setSelfPaidEMIUser] = useState<boolean>(false);
  const [isMobileInputInvalid, setIsMobileInputInvalid] = useState<boolean>(false);
  const [isAlternativeMobileInputInvalid, setIsAlternativeMobileInputInvalid] = useState<boolean>(false);
  const [emiOptionsData, setEmiOptionsData] = useState<IITEMIOptions>();

  const { data: profile, refetch: refetchProfile, isLoading: isProfileLoading } = useAdminProfile(email);

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfileByEmail, {
    onSuccess: () => {
      snackbar.success("Profile Updated Successfully");
      history.push("/admin/users");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleEMIDataChange = () => {
    const updateEMIForm = removeInvalidKeyValueFromEMIForm(emiOptionsData as IITEMIOptions);
    if (Object.keys(updateEMIForm).length > 0) {
      updateProfileMutate({
        email: email,
        meta: updateEMIForm,
      });
    }
  };

  const handleSaveDetails = () => {
    const validProfileDetailForm = removeInvalidKeyValues(profileDetailForm as IITGuwahatiProfileDetailInputInterface);

    if (Object.keys(validProfileDetailForm).length > 0) {
      updateProfileMutate({
        email: email,
        meta: validProfileDetailForm,
      });
    }
  };

  useEffect(() => {
    setSelfPaidEMIUser(!!(profile?.meta && profile?.meta?.emiOptionSelected && (profile?.meta?.selfPaid === "true" || profile?.meta?.selfPaid === "True")));
  }, [profile?.meta]);

  // Filling Profile Meta Details in state
  useEffect(() => {
    if (!isProfileLoading && profile && profile?.meta) {
      setProfileDetailForm(profile.meta);
      setEmiOptionsData({
        emiOptionSelected: profile?.meta?.emiOptionSelected,
        selfPaid: profile?.meta?.selfPaid,
        coApplicant: profile?.meta?.coApplicant,
        currentWorkingStatus: profile?.meta?.currentWorkingStatus,
        studentMonthlySalary: profile?.meta?.studentMonthlySalary,
        employmentType: profile?.meta?.employmentType,
        coApplicantEmploymentType: profile?.meta?.coApplicantEmploymentType,
        emiOptionSelectedForRopar: profile?.meta?.emiOptionSelectedForRopar,
        selfPaidForRopar: profile?.meta?.selfPaidForRopar,
        coApplicantForRopar: profile?.meta?.coApplicantForRopar,
        currentWorkingStatusForRopar: profile?.meta?.currentWorkingStatusForRopar,
        studentMonthlySalaryForRopar: profile?.meta?.studentMonthlySalaryForRopar,
        coApplicantEmploymentTypeForRopar: profile?.meta?.coApplicantEmploymentTypeForRopar,
        employmentTypeForRopar: profile?.meta?.employmentTypeForRopar,

        emiOptionSelectedForMandiV2: profile?.meta?.emiOptionSelectedForMandiV2,
        selfPaidForMandiV2: profile?.meta?.selfPaidForMandiV2,
        coApplicantForMandiV2: profile?.meta?.coApplicantForMandiV2,
        currentWorkingStatusForMandiV2: profile?.meta?.currentWorkingStatusForMandiV2,
        studentMonthlySalaryForMandiV2: profile?.meta?.studentMonthlySalaryForMandiV2,
        coApplicantEmploymentTypeForMandiV2: profile?.meta?.coApplicantEmploymentTypeForMandiV2,
        employmentTypeForMandiV2: profile?.meta?.employmentTypeForMandiV2,

        emiOptionSelectedForMandiES: profile?.meta?.emiOptionSelectedForMandiES,
        selfPaidForMandiES: profile?.meta?.selfPaidForMandiES,
        coApplicantForMandiES: profile?.meta?.coApplicantForMandiES,
        currentWorkingStatusForMandiES: profile?.meta?.currentWorkingStatusForMandiES,
        studentMonthlySalaryForMandiES: profile?.meta?.studentMonthlySalaryForMandiES,
        coApplicantEmploymentTypeForMandiES: profile?.meta?.coApplicantEmploymentTypeForMandiES,
        employmentTypeForMandiES: profile?.meta?.employmentTypeForMandiES,
      });
    }
  }, [profile]);

  useEffect(() => {
    refetchProfile();
  }, [email]);

  return (
    <Skeleton p={{ base: "16px", md: "24px" }} h={"100vh"} isLoaded={true}>
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text as="h2" textStyle="h2">
                Update Users EMI Details
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box mt="10px" border="1px solid grey" rounded="12px" p="12px" gap="8px">
              <EMIPlanOptions setEmiOptionsData={setEmiOptionsData} emiOptionsData={emiOptionsData as IITEMIOptions} isForIITRopar={false} amountToBePaid={0} isForIITGuwahati={true} />

              <Button
                isLoading={updateProfileLoading}
                disabled={updateProfileLoading || !emiOptionsData || Object.keys(emiOptionsData || {}).length === 0}
                size="md"
                mt="10px"
                textStyle="btn-md"
                variant="primary"
                onClick={handleEMIDataChange}
              >
                Update EMI Details
              </Button>
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem mt="20px">
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text as="h2" textStyle="h2">
                Update Users KYC Details
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box mt="20px" border="1px solid grey" rounded="12px" p="12px" gap="8px">
              <ProfileDetailStep
                selfPaidEMIUser={selfPaidEMIUser}
                profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
                setProfileDetailForm={setProfileDetailForm}
                profile={profile}
                refetchProfile={refetchProfile}
                isMobileInputInvalid={isMobileInputInvalid}
                setIsMobileInputInvalid={setIsMobileInputInvalid}
                isAlternativeMobileInputInvalid={isAlternativeMobileInputInvalid}
                setIsAlternativeMobileInputInvalid={setIsAlternativeMobileInputInvalid}
                hideDOB={true}
              />
              <Button
                isLoading={updateProfileLoading}
                disabled={updateProfileLoading || !profileDetailForm || Object.keys(profileDetailForm || {}).length === 0}
                size="md"
                textStyle="btn-md"
                variant="primary"
                onClick={handleSaveDetails}
              >
                Update KYC Info
              </Button>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Skeleton>
  );
};

export default UpdateUserDetails;
