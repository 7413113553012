import {
  COUNSELLING_TYPE,
  IIT_MANDI_COUNSELLING_TYPE,
  IIT_MANDI_ES_COUNSELLING_TYPE,
  IIT_MANDI_V2_COUNSELLING_TYPE,
  IIT_ROPAR_COUNSELLING_TYPE,
  IITG_COUNSELLING_TYPE,
  MASAI_COUNSELLING_TYPE,
  MASAI_ONBOARDING_COUNSELLING_TYPE,
  PAYMENT_COUNSELLING_TYPE_IIT_MANDI,
  PAYMENT_COUNSELLING_TYPE_IITG,
  ProfileType,
} from "../api/schemas/schema";

export type RequestCallbackStatusType = {
  status?: "BOOKED" | "COMPLETED";
  source?: string;
};

export function getUserAgeInYears(date_of_birth: string): string {
  const dob = new Date(date_of_birth);
  const today = new Date();

  if (isNaN(dob.getTime())) return "Invalid date";

  const age = today.getFullYear() - dob.getFullYear();
  if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
    return `${age - 1}`;
  } else {
    return `${age}`;
  }
}

export function isEligibleUserAge(date_of_birth: string): boolean {
  if (!date_of_birth) return true;
  const dob = new Date(date_of_birth);
  const today = new Date();

  if (isNaN(dob.getTime())) return false;

  const age = today.getFullYear() - dob.getFullYear();
  if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
    return 18 <= age - 1 && 28 >= age - 1;
  } else {
    return 18 <= age && 28 >= age;
  }
}

export function isUserGratuadedFn(graduationYear: number, graduationMonth: number, hasGivenSpecialPass = false): boolean {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  if (hasGivenSpecialPass) {
    return graduationYear <= 2025 && graduationMonth <= 8;
  }

  if (currentYear > graduationYear) return true;
  //   currentMonth > graduationMonth
  if (currentYear === graduationYear && graduationMonth < 9) {
    return true;
  }

  return false;
}

export function shouldShowBanner(profile: ProfileType, requestCallbackStatusData: RequestCallbackStatusType[], hasUserReservedSeat: boolean) {
  const isMasaiCoursePrefered = profile?.program_profile_type === "MASAI" ? true : false;

  const isIITGCoursePrefered = profile?.program_profile_type === "IIT_GUWAHATI" ? true : false;

  const isIITMandiCoursePrefered = profile?.program_profile_type === "IIT_MANDI" ? true : false;

  if (!profile || isIITGCoursePrefered || isIITMandiCoursePrefered || hasUserReservedSeat) return;
  const status = requestCallbackStatusData.map((item) => item.source);
  const isInEligibleStatusPresent = status.includes(COUNSELLING_TYPE.PROFILE_INELIGIBLE);
  const isEligibleAge = isEligibleUserAge(profile?.date_of_birth || "");

  const hasGivenSpecialPass = profile?.meta?.has_given_special_pass === "True";
  const isGraduated = isUserGratuadedFn(profile?.graduation_year || 0, profile?.graduation_month || 0, hasGivenSpecialPass);

  if (isInEligibleStatusPresent && !isEligibleAge) return COUNSELLING_TYPE.PROFILE_INELIGIBLE;
  if ((!isEligibleAge || !isGraduated || profile?.is_working) && !hasGivenSpecialPass) return COUNSELLING_TYPE.PROFILE_INELIGIBLE;
  if (isEligibleAge && !isGraduated && !hasGivenSpecialPass) return COUNSELLING_TYPE.PROFILE_INELIGIBLE;
  if (isEligibleAge && profile?.is_working) return COUNSELLING_TYPE.PROFILE_INELIGIBLE;

  return isMasaiCoursePrefered ? COUNSELLING_TYPE.REQUEST_CALLBACK : isIITGCoursePrefered ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI;
}

export function isStudyingAndGraduatedFn(isStudying: boolean, graduationYear: number, graduationMonth: number) {
  const isGraduated = isUserGratuadedFn(graduationYear, graduationMonth);
  if (isStudying && isGraduated) return true;
  return false;
}

export function isWorkingAndGraduatedFn(isWorking: boolean, graduationYear: number, graduationMonth: number) {
  const isGraduated = isUserGratuadedFn(graduationYear, graduationMonth);
  if (isWorking && isGraduated) return true;
  return false;
}

export function isRequestCallbackDoneFn(
  allCounsellingStatus: RequestCallbackStatusType[],
  isIITGDashboard: boolean,
  isIITMandiDashboard: boolean,
  isIITRoparDashboard = false,
  isIITGOnbordingCouncelling = false,
  isIITMandiOnbordingCouncelling = false,
  isMasaiOnboardingCouncelling = false,
  isIITMandiV2OnbordingCouncelling = false,
  isIITMandiESOnbordingCouncelling = false
) {
  if (isIITGOnbordingCouncelling) {
    const counsellingStatusArr = Object.values(PAYMENT_COUNSELLING_TYPE_IITG);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => {
      return counsellingStatusArr.includes(counselling.source as PAYMENT_COUNSELLING_TYPE_IITG) && counselling.status !== "COMPLETED";
    });
    return counsellingStatus;
  } else if (isIITMandiOnbordingCouncelling) {
    const counsellingStatusArr = Object.values(PAYMENT_COUNSELLING_TYPE_IIT_MANDI);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => {
      return counsellingStatusArr.includes(counselling.source as PAYMENT_COUNSELLING_TYPE_IIT_MANDI) && counselling.status !== "COMPLETED";
    });
    return counsellingStatus;
  } else if (isIITGDashboard) {
    const counsellingStatusArr = Object.values(IITG_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as IITG_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else if (isIITMandiDashboard) {
    const counsellingStatusArr = Object.values(IIT_MANDI_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as IIT_MANDI_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else if (isIITRoparDashboard) {
    const counsellingStatusArr = Object.values(IIT_ROPAR_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as IIT_ROPAR_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else if (isIITMandiV2OnbordingCouncelling) {
    const counsellingStatusArr = Object.values(IIT_MANDI_V2_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as IIT_MANDI_V2_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else if (isIITMandiESOnbordingCouncelling) {
    const counsellingStatusArr = Object.values(IIT_MANDI_ES_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as IIT_MANDI_ES_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else if (isMasaiOnboardingCouncelling) {
    const counsellingStatusArr = Object.values(MASAI_ONBOARDING_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as MASAI_ONBOARDING_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  } else {
    const counsellingStatusArr = Object.values(MASAI_COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus?.find((counselling) => counsellingStatusArr.includes(counselling.source as MASAI_COUNSELLING_TYPE) && counselling.status !== "COMPLETED");
    return counsellingStatus;
  }
}

export const isInEligibleUserFn = (profile: ProfileType): boolean => {
  const isEligibleAge = isEligibleUserAge(profile?.date_of_birth || "");
  const isGratudated = isUserGratuadedFn(profile?.graduation_year || 0, profile?.graduation_month || 0);
  if (!isGratudated || !isEligibleAge || profile?.is_working) return true;
  return false;
};

export const getCounsellingSource = (path: string) => {
  switch (path) {
    case "/": {
      return COUNSELLING_TYPE.HOME_COUNSELLING_ELIGIBLE;
    }
    case "/courses": {
      return COUNSELLING_TYPE.COURSES_COUNSELLING_ELIGIBLE;
    }
    case "/activities": {
      return COUNSELLING_TYPE.EVENTS_COUNSELLING_ELIGIBLE;
    }
    case "/msat": {
      return COUNSELLING_TYPE.MSAT_COUNSELLING_ELIGIBLE;
    }

    default: {
      return COUNSELLING_TYPE.COUNSELLING_ELIGIBLE;
    }
  }
};

export const getCounsellingIneligibleSourceType = (path: string) => {
  switch (path) {
    case "/": {
      return COUNSELLING_TYPE.HOME_COUNSELLING_INELIGIBLE;
    }
    case "/courses": {
      return COUNSELLING_TYPE.COURSES_COUNSELLING_INELIGIBLE;
    }
    case "/activities": {
      return COUNSELLING_TYPE.EVENTS_COUNSELLING_INELIGIBLE;
    }
    case "/msat": {
      return COUNSELLING_TYPE.MSAT_COUNSELLING_INELIGIBLE;
    }

    default: {
      return COUNSELLING_TYPE.COUNSELLING_INELIGIBLE;
    }
  }
};
