import { useEffect, useState } from "react";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import ProfileDetailDirectFlowForm from "./ProfileDetailDirectFlowForm";
import IITOnboardingAadharPanForm from "../../IIT-Onboarding/IITOnboardingAadharPanForm";
import { IITGuwahatiProfileDetailInputInterface } from "../../../constants/IIt-course/iit-form-onboarding";
import { useGetPaymentStatus } from "../../../api/utils/hooks/usePayment";
import CoApplicantForm from "./CoApplicantForm";
import { ProfileType } from "../../../api/schemas/schema";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import DateSelect from "../../common/DateSelect";
import dayjs from "dayjs";

interface Props {
  isDuplicateNumber?: boolean;
  selfPaidEMIUser: boolean;
  profileDetailForm: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm: (data: IITGuwahatiProfileDetailInputInterface) => void;
  profile?: ProfileType;
  refetchProfile?: () => void;
  dob?: string;
  setDOB?: (input: string) => void;
  isMobileInputInvalid: boolean;
  setIsMobileInputInvalid: (arg: boolean) => void;
  isAlternativeMobileInputInvalid: boolean;
  setIsAlternativeMobileInputInvalid: (arg: boolean) => void;
  hideDOB?: boolean;
}

const ProfileDetailStep = ({
  isDuplicateNumber = false,
  selfPaidEMIUser,
  profileDetailForm,
  setProfileDetailForm,
  profile,
  refetchProfile,
  dob,
  setDOB,
  isMobileInputInvalid,
  setIsMobileInputInvalid,
  isAlternativeMobileInputInvalid,
  setIsAlternativeMobileInputInvalid,
  hideDOB,
}: Props) => {
  const isIITRopar = profile?.program_profile_type === ProgramProfileType.IIT_ROPAR;
  const isIITG = profile?.program_profile_type === ProgramProfileType.IIT_GUWAHATI;
  const isIITMandi = profile?.program_profile_type === ProgramProfileType.IIT_MANDI;
  const isIITMandiV2 = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_V2;
  const isIITMandiES = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_ES;

  const [isAadhaarVerified, setIsAadhaarVerified] = useState<boolean>(false);

  const { data: semesterPaymentData } = useGetPaymentStatus("SEMESTER_FEES");

  const hasUserPaidSemesterFees = isIITRopar
    ? semesterPaymentData?.is_iit_ropar_course && semesterPaymentData?.status === "PAID"
    : isIITMandi
    ? semesterPaymentData?.is_iit_mandi_course && semesterPaymentData?.status === "PAID"
    : isIITG
    ? semesterPaymentData?.is_iit_guwahati_course && semesterPaymentData?.status === "PAID"
    : isIITMandiV2
    ? semesterPaymentData?.is_iit_mandi_v2_course && semesterPaymentData?.status === "PAID"
    : isIITMandiES
    ? semesterPaymentData?.is_iit_mandiES_course && semesterPaymentData?.status === "PAID"
    : false;

  useEffect(() => {
    refetchProfile && refetchProfile();
  }, [profileDetailForm]);

  useEffect(() => {
    if (profile && profile?.meta) {
      setIsAadhaarVerified(!!(profile?.aadhaar_verified && profile?.meta?.aadharFront && profile?.meta?.aadharBack));
    }
  }, [profile]);

  return (
    <Box>
      <Text textStyle={"h5"} mb={9}>
        We need some basic details for your admission
      </Text>

      {/* Date of birth */}
      <Text textStyle={"h5"} mb={4}>
        Your Personal Info
      </Text>
      <Box display={hideDOB ? "none" : "grid"} mb={4}>
        <Text mb={{ base: "ms-8", md: "8px" }} textStyle="body2-md" color="ms-red.900">
          Enter your date of birth *
        </Text>
        <DateSelect
          showRelativeDate={true}
          onChange={(value) => {
            if (setDOB) {
              setDOB(value.format("YYYY-MM-DD"));
              setProfileDetailForm({ ...profileDetailForm, dob: value.format("YYYY-MM-DD") });
            }
          }}
          value={dob ? dayjs(dob) : null}
          disabled={!!profile?.date_of_birth}
          w={["100%", "30%", "40%", "40%"]}
        />
      </Box>

      <IITOnboardingAadharPanForm
        isCoApplicantInfo={!hasUserPaidSemesterFees && !selfPaidEMIUser}
        isIITGOnbaording={true}
        profile={profile as ProfileType}
        isAadhaarVerified={isAadhaarVerified}
        profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
        setProfileDetailForm={setProfileDetailForm}
      />

      {hasUserPaidSemesterFees ? (
        <ProfileDetailDirectFlowForm
          isDuplicateNumber={isDuplicateNumber}
          profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
          setProfileDetailForm={setProfileDetailForm}
          hasUserPaidSemesterFees={true}
          isMobileInputInvalid={isMobileInputInvalid}
          setIsMobileInputInvalid={setIsMobileInputInvalid}
          isAlternativeMobileInputInvalid={isAlternativeMobileInputInvalid}
          setIsAlternativeMobileInputInvalid={setIsAlternativeMobileInputInvalid}
          profile={profile as ProfileType}
        />
      ) : selfPaidEMIUser ? (
        <ProfileDetailDirectFlowForm
          isDuplicateNumber={isDuplicateNumber}
          selfPaidEMIUser={selfPaidEMIUser}
          profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
          setProfileDetailForm={setProfileDetailForm}
          hasUserPaidSemesterFees={false}
          isMobileInputInvalid={isMobileInputInvalid}
          setIsMobileInputInvalid={setIsMobileInputInvalid}
          isAlternativeMobileInputInvalid={isAlternativeMobileInputInvalid}
          setIsAlternativeMobileInputInvalid={setIsAlternativeMobileInputInvalid}
          profile={profile as ProfileType}
        />
      ) : (
        <CoApplicantForm
          profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface}
          setProfileDetailForm={setProfileDetailForm}
          profile={profile as ProfileType}
          isAadhaarVerified={isAadhaarVerified}
          isMobileInputInvalid={isMobileInputInvalid}
          setIsMobileInputInvalid={setIsMobileInputInvalid}
        />
      )}
      {!profile?.is_admin && (
        <Checkbox
          maxW={"600px"}
          display={"flex"}
          alignItems={"baseline"}
          gap={"3px"}
          mt={"20px"}
          defaultChecked={
            isIITRopar
              ? profileDetailForm?.creditInforConcentForRopar === "true" || profileDetailForm?.creditInforConcentForRopar === "True"
              : isIITMandiV2
              ? profileDetailForm?.creditInforConcentForMandiV2 === "true" || profileDetailForm?.creditInforConcentForMandiV2 === "True"
              : isIITMandiES
              ? profileDetailForm?.creditInforConcentForMandiES === "true" || profileDetailForm?.creditInforConcentForMandiES === "True"
              : profileDetailForm?.creditInforConcent === "True" || profileDetailForm?.creditInforConcent === "true"
          }
          textStyle={"body2"}
          fontSize={"14px"}
          onChange={(data) => {
            setProfileDetailForm(
              isIITRopar
                ? { ...profileDetailForm, creditInforConcentForRopar: data.target.checked ? "True" : "False" }
                : isIITMandiV2
                ? { ...profileDetailForm, creditInforConcentForMandiV2: data.target.checked ? "True" : "False" }
                : isIITMandiES
                ? { ...profileDetailForm, creditInforConcentForMandiES: data.target.checked ? "True" : "False" }
                : {
                    ...profileDetailForm,
                    creditInforConcent: data.target.checked ? "True" : "False",
                  }
            );
          }}
        >
          <Text textStyle={"body2"}>Yes, I give consent to lending partner to review my/co-applicant&apos;s credit information report with Credit Information Companies such as TransUnion CIBIL</Text>
        </Checkbox>
      )}
    </Box>
  );
};

export default ProfileDetailStep;
