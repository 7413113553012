import { useHistory } from "react-router-dom";
import { COUNSELLING_TYPE } from "../../../api/schemas/schema";
import { useContext, useEffect, useState } from "react";
import { Constants } from "../../../components/common/constants";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { Box, Button, Input, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import { ModalTemplate } from "../../../components/common/general";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import { sendTrackingEvent } from "../../../components/common/utils/gtm";
import { useGetPaymentStatus } from "../../../api/utils/hooks/usePayment";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import CounsellingDrawerContent from "../../../components/V2/ModalContent/CounsellingDrawerContent";
import { useMutation } from "react-query";
import { sendBorucherOnEmail, updateProfile } from "../../../api/utils/api/v2/profile";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../../components/common/general/SnackBar";
import { isRequestCallbackDoneFn } from "../../../utils/user";
import GetUserDetailsForIITG from "../../../components/V2/RequestCallback/getUserDetailsForIITG";
import { AddressType } from "../../../types/onboarding.types";
import { isAddressEmpty } from "../../../utils/utils";
import IITCourseAddressForm from "../../../components/iit-course/IITCourseAddressForm";
import { HandleRoparStudents } from "../../../api/utils/api/v2/payment";
import { sendEmailOtpV2 } from "../../../api/utils/api";
import EmailVerifyModalV2 from "../../../components/common/V2/EmailVerifyModalV2";

const IITMandiV2Dashboard = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [state, dispatch] = useContext(AppContext);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [btnText, setBtnText] = useState<string>("Pay");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openAddAddressModal, setOpenAddAddressModal] = useState<boolean>(false);
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<number>(1);
  const [isRequestCallbackDone, setIsRequestCallbackDone] = useState<boolean>(false);
  const [openCounsellingSuccessModal, setOpenCounsellingSuccessModal] = useState<boolean>(false);
  const [address, setAddress] = useState<AddressType>({
    apartment: "",
    street: "",
    landmark: "",
    city: "",
    cityId: 0,
    state: "",
    pincode: 0,
    gender: "",
    tshirtSize: "",
    studentName: "",
    passportSizePic: "",
  });
  const [showEmailOtpModal, setShowEmailOtpModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);

  const { mutate: sendEmailOtpMutate, isLoading: sendEmailOtpLoading } = useMutation(sendEmailOtpV2, {
    onSuccess: () => {
      snackbar.success("Otp sent on email!");
      if (!showEmailOtpModal) {
        setShowEmailOtpModal(true);
      }
      if (showUpdateEmailModal) {
        setShowUpdateEmailModal(false);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { data: activeApplication, isLoading: isActiveApplicationDataLoading, refetch: applicationRefetch } = useActiveApplication();
  const { data: paymentData, isLoading: isRegisterationPaymenStatusLoading, refetch: refetchRegPaymentData } = useGetPaymentStatus("REGISTRATION_FEES");
  const { data: semesterPaymentData, isLoading: isSemesterPaymentStatusLoading, refetch: refetchSemesterFees } = useGetPaymentStatus("SEMESTER_FEES");
  const { data: semesterEMIPaymentData, isLoading: isSemesterEMIPaymentStatusLoading } = useGetPaymentStatus("SEMESTER_FEES_EMI");
  const { data: allCounsellingStatus, isLoading: allCounsellingStatusLoading } = useAllCounsellingStatus();
  const { data: profile, isLoading: isProfileLoading, refetch: refetchProfile } = useProfile((data) => {
    dispatch({
      type: ApplicationActionType.SET_COURSE_PREFERENCE,
      payload: {
        coursePreference: data?.program_profile_type as ProgramProfileType,
      },
    });
  });

  const { mutate: sendBorucher } = useMutation(sendBorucherOnEmail, {
    onSuccess: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      refetchProfile();
      snackbar.success("Address updated successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handleRoparStudents, isLoading: roparStudentEnrollmentLoading } = useMutation(HandleRoparStudents, {
    onSuccess: () => {
      refetchRegPaymentData();
      applicationRefetch();
      refetchProfile();
      refetchSemesterFees();
      snackbar.success("Course added successfully!");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const isForIITG = profile?.program_profile_type === "IIT_GUWAHATI";
  const isForIITRopar = profile?.program_profile_type === "IIT_ROPAR";
  const isForIITMandiES = profile?.program_profile_type === "IIT_MANDI_ES";
  const hasUserPaidSemesterFees = semesterPaymentData?.is_iit_mandiES_course && semesterPaymentData?.status === "PAID" ? true : false;
  const hasUserPaidSemesterEMIFees = semesterEMIPaymentData?.is_iit_mandiES_course && semesterEMIPaymentData?.status === "PAID" ? true : false;
  const hasUserReservedSeat = paymentData?.status === "PAID" && paymentData?.is_iit_mandiES_course;
  const shouldShowDetailsModal =
    !!(
      profile &&
      (!profile?.other_address || (profile?.other_address && !profile?.other_address?.currentlyWorking)) &&
      (!profile?.meta || (profile?.meta?.hasSkippedLaunchForm !== "True" && !profile?.meta?.hasFilledLaunchFormDetails))
    ) ||
    (profile?.aadhaar_verified && profile?.meta?.hasSkippedLaunchForm === "True");

  const handleBtnClick = () => {
    const isEmailValidForIITMandi = false;
    if (isEmailValidForIITMandi) {
      setShowUpdateEmailModal(true);
    } else if (!hasUserPaidSemesterEMIFees && profile?.meta?.emiOptionSelectedForMandiES) {
      history.push("/minor-in-embedded-system-onboarding");
    } else {
      if (currentOnboardingStep === 1) {
        history.push("/registration-fees");
      } else if (isAddressEmpty(address)) {
        sendTrackingEvent({
          event: "iit_mandi_v2_delivery_add",
          eventLabel: window.location.href,
        });
        setOpenAddAddressModal(true);
      } else if (currentOnboardingStep === 2) {
        history.push("/minor-in-embedded-system-onboarding");
      } else if (currentOnboardingStep === 3) {
        history.push("/minor-in-embedded-system-onboarding");
      } else if (currentOnboardingStep === 4) {
        history.push("/minor-in-embedded-system-onboarding");
      }
    }
  };

  useEffect(() => {
    const shouldShowDownloadBrochure = window.location.search.includes("brochure");
    if (shouldShowDownloadBrochure) {
      snackbar.success("Brochure has been sent to your email.");
      sendBorucher({
        send_broucher_email: true,
      });
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    const showNewIITMandi = window.location.search.includes("showNewIITMandi");
    if (showNewIITMandi) {
      updateProfile({
        program_profile_type: ProgramProfileType.IIT_MANDI_V2,
      });
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    const isIITGDashboard = state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
    const isIITMandiDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI;
    // get counselling status with source as request callback
    if (allCounsellingStatus && allCounsellingStatus.length > 0) {
      const counsellingStatus = isRequestCallbackDoneFn(allCounsellingStatus, isIITGDashboard, isIITMandiDashboard, false, false, false, false, false, true);
      if (counsellingStatus) {
        setIsRequestCallbackDone(true);
      }
    }
  }, [allCounsellingStatus, state]);

  useEffect(() => {
    if (profile?.other_address) {
      setAddress(profile?.other_address as AddressType);
    }
  }, [profile, profile?.other_address]);

  useEffect(() => {
    const isRegisterationDone = paymentData && hasUserReservedSeat;
    const isOrientationVideoWatched = profile?.meta && profile?.meta?.hasClickedOnJoinSlackForMandiES === "True";
    const isOnboardingCompleted = activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE";
    const isEmailValidForIITMandiV2 = false;
    if (paymentData && !isRegisterationDone) {
      setCurrentOnboardingStep(1);
      if (profile && isEmailValidForIITMandiV2) {
        if (isEmailValidForIITMandiV2 && !profile.email_verified) {
          setBtnText("Verify Email to get Free Access");
        } else {
          setBtnText("Get Free Access");
        }
      } else {
        setBtnText("PAY REGISTRATION FEE");
      }
    } else if (isAddressEmpty(address)) {
      setBtnText("Add Delivery Instructions");
    } else if (!isOrientationVideoWatched) {
      setCurrentOnboardingStep(2);
      //   setBtnText("Watch ORIENTATION VIDEOS");
      setBtnText("Start Onboarding");
    } else if (profile?.meta && !profile?.meta?.emiOptionSelectedForMandiES && !hasUserPaidSemesterFees) {
      setCurrentOnboardingStep(3);
      setBtnText("Pay COURSE Fees now");
    } else if (!isOnboardingCompleted) {
      setCurrentOnboardingStep(4);
      setBtnText("FILL PROFILE DETAILS");
    } else if (isOnboardingCompleted) {
      if (!hasUserPaidSemesterEMIFees && profile?.meta?.emiOptionSelectedForMandiES) {
        setBtnText("Pay 1st EMI");
      } else {
        setBtnText("Admission Completed");
      }
      setCurrentOnboardingStep(5);
    }
  }, [paymentData, semesterPaymentData, activeApplication, profile, hasUserPaidSemesterEMIFees, address]);

  if (isProfileLoading || isSemesterPaymentStatusLoading || isRegisterationPaymenStatusLoading || isActiveApplicationDataLoading || allCounsellingStatusLoading || isSemesterEMIPaymentStatusLoading) {
    return <Skeleton h={`calc(100vh - ${Constants.headerHeight})`} w={{ base: "100vw" }} isLoaded={false} />;
  }

  const masaiWebsiteUrl = `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}`;

  return (
    <Box display={"flex"} flexDir={"column"} gap={{ base: "24px", md: "30px" }} pos={"relative"} minH={`calc(100vh - ${Constants.headerHeight})`} mx="auto">
      <iframe
        style={{
          width: "100%",
          height: `calc(100vh - ${Constants.headerHeight})`,
        }}
        src={`${masaiWebsiteUrl}/minor-in-es-onward?hideFooter=true&hideNavbar=true&032572f9208635a253425c7290509f2d=true${
          state.shouldBookForIITMandiCounsellingEvent && "&scrollToCounsellingCard=true"
        }${isForIITMandiES && "&hideCounsellingBanner=true"}`}
        title="iit-animation"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      {/* Sticky Buttons */}
      <Box
        display={"flex"}
        gap={2}
        flexDir={{ base: "column", md: "row-reverse" }}
        bg={"#FFF"}
        px={{ base: 4, md: 0 }}
        bottom={0}
        py={{ base: 3, md: 4 }}
        boxShadow={"0px -1px 0px 0px #D9D9D9"}
        pos={"fixed"}
        w={"full"}
        textAlign={"end"}
        right={{ base: 0 }}
        zIndex={{ base: 10, lg: 1 }}
      >
        <Button
          isLoading={roparStudentEnrollmentLoading}
          variant="primary"
          size={isMobile ? "sm" : "md"}
          mr={4}
          onClick={handleBtnClick}
          disabled={currentOnboardingStep === 5 && !(!hasUserPaidSemesterEMIFees && profile?.meta?.emiOptionSelectedForMandiES)}
        >
          {btnText}
        </Button>
        <Button
          variant="secondary"
          size={isMobile ? "sm" : "md"}
          mr={4}
          onClick={() => {
            sendTrackingEvent({
              event: "d_header_callback_iit_mandi_v2",
              eventLabel: window.location.pathname,
            });
            setOpenDrawer(true);
          }}
          // dispaly={"none"}
          isDisabled={isRequestCallbackDone || allCounsellingStatusLoading}
        >
          Request Callback
        </Button>
      </Box>

      <ModalTemplate
        title=""
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        showCloseButtonIcon={false}
      >
        <CounsellingDrawerContent
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={
            isForIITG
              ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG
              : isForIITRopar
              ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_ROPAR
              : isForIITMandiES
              ? COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI_ES
              : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI
          }
          setOpenDrawer={setOpenDrawer}
        />
      </ModalTemplate>
      {shouldShowDetailsModal && (
        <ModalTemplate
          modalOverlayProps={{
            backgroundColor: "rgba(1, 10, 21, 0.8)",
          }}
          title={""}
          isOpen={true}
          onClose={() => {
            console.log("Close modal");
          }}
          showCloseButtonIcon={false}
          marginX={2}
          maxHeight="80vh"
          scrollBehavior="inside"
        >
          <GetUserDetailsForIITG />
        </ModalTemplate>
      )}
      <ModalTemplate
        title=""
        isOpen={openAddAddressModal}
        onClose={() => {
          setOpenAddAddressModal(false);
        }}
        marginX={2}
        showCloseButtonIcon={true}
        showCloseButton={true}
        maxWidth="464px"
      >
        <IITCourseAddressForm
          addressData={address}
          setAddressData={setAddress}
          loading={updateProfileLoading}
          setOpenAddAddressModal={setOpenAddAddressModal}
          onSubmit={(data) => {
            updateProfileMutate({
              other_address: data,
            });
          }}
        />
      </ModalTemplate>

      <ModalTemplate
        title=""
        isOpen={showUpdateEmailModal}
        onClose={() => {
          setShowUpdateEmailModal(false);
        }}
        marginX={4}
        showCloseButtonIcon={true}
        showCloseButton={true}
        maxWidth="464px"
      >
        <Box display={"grid"} mt={"ms-16"} p={"20px"}>
          <Text mb={"10px"} textStyle="body2-md" color="ms-red.900">
            Kindly Verify your Email address*
          </Text>
          <Input value={profile && profile.email} placeholder="Enter your email" disabled={true} />

          <Button
            onClick={() => {
              sendTrackingEvent({
                event: "d_profile_email_verify",
                eventLabel: location.pathname,
              });
              sendEmailOtpMutate(profile?.email || "");
            }}
            variant="primary"
            my={"0px"}
            mt={"15px"}
            size={isMobile ? "sm" : "md"}
            isLoading={sendEmailOtpLoading}
          >
            Verify
          </Button>
        </Box>
      </ModalTemplate>

      {/* Email Verify */}
      <EmailVerifyModalV2
        showEmailOtpModal={showEmailOtpModal}
        setShowEmailOtpModal={setShowEmailOtpModal}
        updatedEmail={profile?.email}
        setShowUpdateEmailModal={setShowUpdateEmailModal}
        handleRoparStudents={handleRoparStudents}
      />
    </Box>
  );
};

export default IITMandiV2Dashboard;
