import { Box, Button, Image, Radio, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { IITEMIOptions, PaymentMethod } from "../../constants/IIt-course/iit-form-onboarding";
import usePayment, { useGetPaymentStatus, useGetSemesterFee } from "../../api/utils/hooks/usePayment";
import { sendTrackingEvent } from "../common/utils/gtm";
import { AppContext } from "../../context/ApplicationContext";
import calculateFinalPayment from "../common/V2/CalculateFinalPayment";
import { useProfile } from "../../api/utils/hooks/useProfile";
import { COUNSELLING_TYPE, SemesterPaymentType } from "../../api/schemas/schema";
import { ModalTemplate } from "../common/general";
import CounsellingDrawerContent from "../V2/ModalContent/CounsellingDrawerContent";
import BottomDrawer from "../common/V2/BottomDrawer";
import { isRequestCallbackDoneFn } from "../../utils/user";
import { useAllCounsellingStatus } from "../../api/utils/hooks/useCounsellingStatus";
import { useHistory } from "react-router-dom";
import useSnackBar from "../common/general/SnackBar";
import { CourseSelected, ProgramProfileType } from "../../enums/ProgramType.enum";
import EMIPlanOptions from "../V2/IITGOnboarding/EMIPlanOptions";
import dayjs from "dayjs";
import { dayLeftFor1TrimesterFee } from "../../utils/utils";
import CouponInput from "./CouponInput";
import SemesterFeesOptions from "../V2/IITGOnboarding/SemesterFeeOptions";
import { axiosErrorHandler } from "../../api/utils/error";
import { useMutation } from "react-query";
import { getActiveBatch } from "../../api/utils/api/v2/batch";
import { CreatePhonePayPayment, GetPhonePayPaymentStatus } from "../../api/utils/api/v2/payment";

interface Props {
  activeTab: PaymentMethod;
  setActiveTab: (args: PaymentMethod) => void;
  setIsEMIPaymentCallbackRequested?: (args: boolean) => void;
  isIITGuwahati?: boolean;
  setEmiOptionsData?: (data: IITEMIOptions) => void;
  emiOptionsData?: IITEMIOptions;
  handleSubmit?: () => void;
  updateProfileLoading?: boolean;
  endDateForPayment?: { date: string; overdue: boolean };
  registerationPaymentDate?: string;
  disablePaymentBtn?: boolean;
  setDisablePaymentBtn?: (value: boolean) => void;
  isForIITRopar?: boolean;
  setDiscountPrice?: (value: number) => void;
  setCouponCode?: (value: string) => void;
  shouldHideDirectPayment: boolean;
  setSemesterPaymentOptionsData?: (data: SemesterPaymentType) => void;
  semesterPaymentOptionsData?: SemesterPaymentType;
  isForIITMandiV2?: boolean;
  isForIITMandiES?: boolean;
}

const IITFeePaymentStep = ({
  activeTab,
  setActiveTab,
  setIsEMIPaymentCallbackRequested,
  isIITGuwahati,
  setEmiOptionsData,
  handleSubmit,
  emiOptionsData,
  endDateForPayment,
  updateProfileLoading,
  registerationPaymentDate,
  isForIITRopar,
  setDisablePaymentBtn,
  setDiscountPrice,
  setCouponCode,
  shouldHideDirectPayment,
  setSemesterPaymentOptionsData,
  semesterPaymentOptionsData,
  isForIITMandiV2,
  isForIITMandiES,
}: Props) => {
  const paymentOptions = [
    { title: "Pay in EMIs", paymentType: PaymentMethod.EMI_PAYMENT },
    { title: "Pay full amount", paymentType: PaymentMethod.ONE_TIME_PAYMENT },
  ];

  const [appState] = useContext(AppContext);
  const history = useHistory();
  const snackbar = useSnackBar();

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [selectedCourseId, setSelectedCourseId] = useState<string>();
  const [paymentAmount, setPaymentAmount] = useState<undefined | number>(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCounsellingSuccessModal, setOpenCounsellingSuccessModal] = useState(false);
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [absolutePaymentAmount, setAbsolutePaymentAmount] = useState(0);
  const [prevFinal, setPrevFinal] = useState(0);
  const [couponValue, setCouponValue] = useState("");

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isRequestCallbackDone, setIsRequestCallbackDone] = useState(false);

  const { data: paymentData, refetch: refetchSemesterFees } = useGetPaymentStatus("SEMESTER_FEES");

  const { data: profile, refetch: refetchProfile } = useProfile();
  const { data: semesterData, isLoading } = useGetSemesterFee(profile?.program_profile_type as CourseSelected);

  const { startRazorPay, isLoading: isPaymentLoading, isApiLoading: isPaymentApiLoading } = usePayment("SEMESTER_FEES", profile?.program_profile_type as ProgramProfileType);

  const { data: allCounsellingStatus, isLoading: allCounsellingStatusLoading } = useAllCounsellingStatus();

  const { mutate: createPhonePayPayment, isLoading: isPhonePayApiLoading } = useMutation(CreatePhonePayPayment, {
    onSuccess: (data) => {
      const paymentUrl = data?.redirect_url;
      window.open(paymentUrl, "_self");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: verifyPhonePayPayment, isLoading: isVerifyPhonePayApiLoading } = useMutation(GetPhonePayPaymentStatus, {
    onSuccess: () => {
      refetchSemesterFees();
      // remove query param from url
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handlePaymentCreateOption, isLoading: isActiveApiBatchLoading } = useMutation(getActiveBatch, {
    onSuccess: (data) => {
      const isPhonePayEnabled = data && data.meta_data && data.meta_data.isPhonePayEnabled === "True";

      if (isPhonePayEnabled) {
        const redirectUrl = window.location.pathname;

        if (discountAmount && discountAmount > 0) {
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES",
            redirect_url: redirectUrl,
            coupon_code: couponValue,
            semester_payent_type: semesterPaymentOptionsData,
          });
        } else {
          createPhonePayPayment({
            course_id: Number(selectedCourseId),
            payment_type: "SEMESTER_FEES",
            redirect_url: redirectUrl,
            semester_payent_type: semesterPaymentOptionsData,
          });
        }
      } else {
        if (discountAmount && discountAmount > 0) {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES", couponValue, semesterPaymentOptionsData);
        } else {
          startRazorPay(selectedCourseId || "", "SEMESTER_FEES", null, semesterPaymentOptionsData);
        }
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = dayjs();
    const endDate = dayLeftFor1TrimesterFee(registerationPaymentDate || "").date;
    const endDateTime = dayjs(endDate);
    const difference = endDateTime.diff(now);

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  // Calculating all the possible changes to payment amount
  useEffect(() => {
    if (semesterData) {
      let semsterFee = semesterData?.full_course_fees || 0;
      if (semesterPaymentOptionsData === "FULLY_PAID") {
        semsterFee = semesterData?.full_course_fees || 0;
      } else if (semesterPaymentOptionsData === "PARTIALLY_PAID") {
        semsterFee = semesterData?.partial_semester_one_fees || 0;
      }
      const { finalAmount } = calculateFinalPayment({
        registrationFee: semsterFee,
      });

      const courseId = semesterData?.id.toString() || "";

      setSelectedCourseId(courseId);
      setPaymentAmount(finalAmount);

      setPrevFinal(finalAmount);
      setAbsolutePaymentAmount(finalAmount);
    }
  }, [profile, semesterData, semesterPaymentOptionsData]);

  useEffect(() => {
    if (isPaymentLoading) setIsPaymentModalOpen(true);
    else {
      setIsPaymentModalOpen(false);
    }
  }, [isPaymentLoading]);

  useEffect(() => {
    const isIITGDashboard = appState.coursePreference === ProgramProfileType.IIT_GUWAHATI ? true : false;
    const isIITMandiDashboard = appState.coursePreference === ProgramProfileType.IIT_MANDI ? true : false;
    // get counselling status with source as request callback
    if (allCounsellingStatus && allCounsellingStatus.length > 0) {
      const counsellingStatus = isRequestCallbackDoneFn(allCounsellingStatus, isIITGDashboard, isIITMandiDashboard, isIITGDashboard, isIITMandiDashboard);
      if (counsellingStatus && setIsEMIPaymentCallbackRequested) {
        setIsRequestCallbackDone(true);
        setIsEMIPaymentCallbackRequested(true);
      }
    }
  }, [allCounsellingStatus, appState]);

  const handleDisablePaymentBtn = () => {
    const shouldDisable = isRequestCallbackDone || allCounsellingStatusLoading;
    if (
      (!emiOptionsData?.emiOptionSelected && !isForIITRopar && !isForIITMandiV2 && !isForIITMandiES) ||
      (!emiOptionsData?.emiOptionSelectedForRopar && isForIITRopar) ||
      (!emiOptionsData?.emiOptionSelectedForMandiV2 && isForIITMandiV2) ||
      (!emiOptionsData?.emiOptionSelectedForMandiES && isForIITMandiES)
    ) {
      if (setDisablePaymentBtn) {
        setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
      }
      return true;
    }
    if (
      (!emiOptionsData?.currentWorkingStatus && !isForIITRopar && !isForIITMandiES && !isForIITMandiES) ||
      (!emiOptionsData?.currentWorkingStatusForRopar && isForIITRopar) ||
      (!emiOptionsData?.currentWorkingStatusForMandiV2 && isForIITMandiV2) ||
      (!emiOptionsData?.currentWorkingStatusForMandiV2 && isForIITMandiES)
    ) {
      if (setDisablePaymentBtn) {
        setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
      }
      return true;
    }
    if (
      emiOptionsData?.currentWorkingStatus === "currently_working" ||
      emiOptionsData?.currentWorkingStatusForRopar === "currently_working" ||
      emiOptionsData?.currentWorkingStatusForMandiV2 === "currently_working" ||
      emiOptionsData?.currentWorkingStatusForMandiES === "currently_working"
    ) {
      if (emiOptionsData?.studentMonthlySalary === "above_15000" && !emiOptionsData?.employmentType && !isForIITRopar && !isForIITMandiV2 && !isForIITMandiES) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (
        (emiOptionsData?.studentMonthlySalaryForRopar === "above_15000" && !emiOptionsData?.employmentTypeForRopar && isForIITRopar) ||
        (emiOptionsData?.studentMonthlySalaryForMandiV2 === "above_15000" && !emiOptionsData?.employmentTypeForMandiV2 && isForIITMandiV2) ||
        (emiOptionsData?.studentMonthlySalaryForMandiES === "above_15000" && !emiOptionsData?.employmentTypeForMandiES && isForIITMandiES)
      ) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (
        emiOptionsData?.studentMonthlySalary === "below_15000" &&
        !isForIITRopar &&
        !isForIITMandiV2 &&
        !isForIITMandiES &&
        (!emiOptionsData?.coApplicant || !emiOptionsData?.coApplicantEmploymentType)
      ) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (
        (emiOptionsData?.studentMonthlySalaryForRopar === "below_15000" && isForIITRopar && (!emiOptionsData?.coApplicantForRopar || !emiOptionsData?.coApplicantEmploymentTypeForRopar)) ||
        (emiOptionsData?.studentMonthlySalaryForMandiV2 === "below_15000" && isForIITMandiV2 && (!emiOptionsData?.coApplicantForMandiV2 || !emiOptionsData?.coApplicantEmploymentTypeForMandiV2)) ||
        (emiOptionsData?.studentMonthlySalaryForMandiES === "below_15000" && isForIITMandiES && (!emiOptionsData?.coApplicantForMandiES || !emiOptionsData?.coApplicantEmploymentTypeForMandiES))
      ) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (!emiOptionsData?.studentMonthlySalary && !isForIITRopar && !isForIITMandiV2 && !isForIITMandiES) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (
        (!emiOptionsData?.studentMonthlySalaryForRopar && isForIITRopar) ||
        (!emiOptionsData?.studentMonthlySalaryForMandiV2 && isForIITMandiV2) ||
        (!emiOptionsData?.studentMonthlySalaryForMandiES && isForIITMandiES)
      ) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (setDisablePaymentBtn) {
        setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? false : false);
      }
      return false;
    } else {
      if ((!emiOptionsData?.coApplicant || !emiOptionsData?.coApplicantEmploymentType) && !isForIITRopar && !isForIITMandiV2 && !isForIITMandiES) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
      if (
        ((!emiOptionsData?.coApplicantForRopar || !emiOptionsData?.coApplicantEmploymentTypeForRopar) && isForIITRopar) ||
        ((!emiOptionsData?.coApplicantForMandiV2 || !emiOptionsData?.coApplicantEmploymentTypeForMandiV2) && isForIITMandiV2) ||
        ((!emiOptionsData?.coApplicantForMandiES || !emiOptionsData?.coApplicantEmploymentTypeForMandiES) && isForIITMandiES)
      ) {
        if (setDisablePaymentBtn) {
          setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? true : false);
        }
        return true;
      }
    }
    if (setDisablePaymentBtn) {
      setDisablePaymentBtn(activeTab === PaymentMethod.EMI_PAYMENT ? !!shouldDisable : false);
    }
    return !!shouldDisable;
  };

  useEffect(() => {
    const merchantTransactionId = location.search.split("merchantTransactionId=")[1];
    if (merchantTransactionId) {
      verifyPhonePayPayment(merchantTransactionId);
    }
  }, [location.search]);

  useEffect(() => {
    refetchProfile();
  }, []);

  useEffect(() => {
    if (profile?.meta && profile?.meta?.emi_payment_coupon_code) {
      setCouponValue(profile?.meta?.direct_payment_coupon_code);
    }
  }, [profile, profile?.meta]);

  const isForIITMandi = profile?.program_profile_type === ProgramProfileType.IIT_MANDI;

  const hasUserPaidSemesterFees = isForIITRopar
    ? paymentData?.status === "PAID" && paymentData?.is_iit_ropar_course
    : isIITGuwahati
    ? paymentData?.status === "PAID" && paymentData?.is_iit_guwahati_course
    : isForIITMandi
    ? paymentData?.status === "PAID" && paymentData?.is_iit_mandi_course
    : isForIITMandiV2
    ? paymentData?.status === "PAID" && paymentData?.is_iit_mandi_v2_course
    : isForIITMandiES
    ? paymentData?.status === "PAID" && paymentData?.is_iit_mandiES_course
    : false;

  const isForIITG = profile?.program_profile_type === ProgramProfileType.IIT_GUWAHATI;

  const hasSelectedProgrammingFoundationClass = isForIITRopar && profile?.meta?.programmingFoundationClass;

  if (isLoading) {
    return <Skeleton h="80vh" isLoaded={!isLoading} />;
  }

  return (
    <>
      <Box
        maxW={isForIITG || isForIITRopar || isForIITMandiV2 || isForIITMandiES ? "auto" : "610px"}
        flex={isForIITG || isForIITRopar || isForIITMandiV2 || isForIITMandiES ? 1 : "auto"}
        boxShadow={{
          md: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);",
        }}
        rounded={{ md: "16px" }}
        bg={{ md: "white" }}
        border={{ md: "1px solid #E5E5E5" }}
        p={{ base: "4px", md: "24px" }}
        display={"flex"}
        flexDir={"column"}
        gap={"24px"}
        justifyContent={"center"}
      >
        <Box display={"flex"} justifyContent={"center"} flexDir={"column"}>
          {!hasSelectedProgrammingFoundationClass ? (
            <Text textStyle={"h5"} display={"inline"} gap={"4px"}>
              Complete fee payment for the {isForIITRopar || isForIITMandiV2 || isForIITMandiES ? "Course Fees by" : "remaining course fees by"}{" "}
              <Text textColor={"#3470E4"} fontStyle={"italic"} display={"inline"}>
                {dayLeftFor1TrimesterFee(registerationPaymentDate || "").date.format("DD MMM YYYY")}
              </Text>
            </Text>
          ) : (
            <Text textStyle={"h5"} display={"inline"} gap={"4px"}>
              Complete the course fee payment and get access to Foundation Classes
            </Text>
          )}
          {!endDateForPayment?.overdue ? (
            <Box display={{ md: "flex" }} bg={"#D6E2FA"} mt={2} rounded={"16px"} flexDir={"column"} gap={1} w={"max-content"}>
              <Box rounded={"4px"} py={"4px"} px={"10px"} fontSize={"13.3px"} color={"#3470E4"} textAlign={"center"}>
                {timeLeft.days} days : {timeLeft.hours} hours : {timeLeft.minutes} mins
              </Box>
            </Box>
          ) : null}
        </Box>

        {(isForIITRopar || isForIITMandiV2 || isForIITMandiES) && activeTab === "ONE_TIME_PAYMENT" ? (
          <Box>
            <CouponInput
              discountedAmount={discountAmount}
              setDiscountedAmount={setDiscountAmount}
              setCouponValue={setCouponValue}
              couponValue={couponValue}
              selectedCoursePrice={prevFinal}
              priceAfterDiscount={absolutePaymentAmount}
              registrationFee={paymentAmount}
              profile={profile}
              selectedCourseId={selectedCourseId || ""}
              setDiscountPrice={setDiscountPrice}
              setCouponCode={setCouponCode}
              activeTab={activeTab}
              semesterPaymentOptionsData={semesterPaymentOptionsData}
              paymentType={"SEMESTER_FEES"}
              refectchFees={refetchSemesterFees}
            />
          </Box>
        ) : null}

        <Box display={shouldHideDirectPayment ? "none" : "flex"} alignItems={"center"} gap={{ base: "12px", md: "24px" }}>
          {paymentOptions.map((item) => {
            return (
              <Box
                flex={1}
                key={item.paymentType}
                px={{ base: 2, md: 4 }}
                py={4}
                rounded={"12px"}
                border={activeTab === item.paymentType ? "2px solid #3470E4" : "2px solid #E5E5E5"}
                onClick={() => {
                  if (hasUserPaidSemesterFees) return;
                  setActiveTab(item.paymentType);
                  handleDisablePaymentBtn();
                }}
                cursor={hasUserPaidSemesterFees ? (item.paymentType === PaymentMethod.EMI_PAYMENT ? "no-drop" : "pointer") : "pointer"}
              >
                <Radio isChecked={activeTab === item.paymentType} cursor={hasUserPaidSemesterFees ? (item.paymentType === PaymentMethod.EMI_PAYMENT ? "no-drop" : "pointer") : "pointer"}>
                  <Text fontSize={{ base: "14px", md: "16px" }} fontWeight={600} textColor={"#21191B"} pl={1}>
                    {item.title}
                  </Text>
                </Radio>
              </Box>
            );
          })}
        </Box>
        <Box
          rounded={"12px"}
          bg={isIITGuwahati ? "#fff" : activeTab === PaymentMethod.EMI_PAYMENT ? "#fff" : "#3B3435"}
          color={"white"}
          px={{ base: "12px", md: "24px" }}
          py={"16px"}
          pos={"relative"}
          overflow={"hidden"}
        >
          {activeTab === PaymentMethod.ONE_TIME_PAYMENT ? (
            <Box display={"flex"} flexDir={"column"} gap={"4px"}>
              <SemesterFeesOptions
                setSemesterPaymentOptionsData={setSemesterPaymentOptionsData}
                semesterPaymentOptionsData={semesterPaymentOptionsData as SemesterPaymentType}
                isForIITRopar={isForIITRopar}
                isForIITMandiV2={isForIITMandiV2}
                isForIITMandiES={isForIITMandiES}
                amountToBePaid={isForIITRopar || isForIITMandiV2 || isForIITMandiES ? (discountAmount ? paymentAmount && paymentAmount - discountAmount : paymentAmount) : paymentAmount}
              />
            </Box>
          ) : isIITGuwahati || isForIITRopar || isForIITMandiV2 ? (
            <EMIPlanOptions
              setEmiOptionsData={setEmiOptionsData}
              emiOptionsData={emiOptionsData as IITEMIOptions}
              isForIITRopar={isForIITRopar}
              isForIITMandiV2={isForIITMandiV2}
              isForIITMandiES={isForIITMandiES}
              amountToBePaid={isForIITRopar || isForIITMandiV2 || isForIITMandiES ? (discountAmount ? paymentAmount && paymentAmount - discountAmount : paymentAmount) : paymentAmount}
              isForIITGuwahati={isForIITG}
            />
          ) : (
            <Box>
              <Text textStyle={{ base: "body2-md", md: "body1-md" }}>Please fill in the details below. Our Admission team will get in touch with you and assist with the further process.</Text>
              <Text textStyle={{ base: "body2-md", md: "body1-md" }} mt={4}>
                Currently we offer 12, 18 and 24 months instalments.
              </Text>
              <Image src="/img/iit-course/paymentInfo.svg" objectFit={"contain"} pos={"absolute"} bottom={0} right={0} />
            </Box>
          )}
        </Box>

        <Box display={{ base: "none", md: "block" }}>
          {activeTab === PaymentMethod.ONE_TIME_PAYMENT ? (
            <>
              <Button
                textTransform={"uppercase"}
                variant="primary"
                w={"full"}
                mt="10px"
                isLoading={isPaymentApiLoading || isActiveApiBatchLoading || isPhonePayApiLoading}
                onClick={() => {
                  sendTrackingEvent({
                    event: "p_pay_iitg_semester",
                    eventLabel: window.location.href,
                    p_amount: (paymentAmount || 0).toString(),
                  });
                  handlePaymentCreateOption(selectedCourseId || "");
                }}
                disabled={hasUserPaidSemesterFees || !selectedCourseId || isVerifyPhonePayApiLoading}
              >
                {hasUserPaidSemesterFees ? "Paid" : "pay course FEE now"}
              </Button>
            </>
          ) : (
            <Button
              textTransform={"uppercase"}
              variant="primary"
              w={"full"}
              onClick={() => {
                if (isIITGuwahati && handleSubmit) {
                  sendTrackingEvent({
                    event: "d_submit_emi_plan_form",
                    eventLabel: window.location.pathname,
                  });
                  handleSubmit();
                }
              }}
              isLoading={updateProfileLoading && updateProfileLoading}
              isDisabled={handleDisablePaymentBtn()}
            >
              submit
            </Button>
          )}
        </Box>
      </Box>

      {/* councelling details modal */}
      <BottomDrawer
        setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
        source={isForIITG ? COUNSELLING_TYPE.SEMSTER_FEES_PAYMENT_COUNCELLING_IIT : COUNSELLING_TYPE.SEMSTER_FEES_PAYMENT_COUNCELLING_IIT_MANDI}
        openDrawer={openDrawer && isMobile ? true : false}
        setOpenDrawer={setOpenDrawer}
      />
      <ModalTemplate
        title=""
        isOpen={openDrawer && !isMobile ? true : false}
        onClose={() => {
          setOpenDrawer(false);
        }}
        showCloseButtonIcon={false}
      >
        <CounsellingDrawerContent
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={isForIITG ? COUNSELLING_TYPE.SEMSTER_FEES_PAYMENT_COUNCELLING_IIT : COUNSELLING_TYPE.SEMSTER_FEES_PAYMENT_COUNCELLING_IIT_MANDI}
          setOpenDrawer={setOpenDrawer}
        />
      </ModalTemplate>
    </>
  );
};

export default IITFeePaymentStep;
